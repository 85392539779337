import React from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

import ScrollToTopButton from '../../components/ScrollToTopButton';

// import businesses_list from "../../../business";
import FeaturedListing from '../../components/FeaturedListing';
import RehabSearchForm from '../../components/RehabSearchForm';
import MedicalReview from '../../components/MedicalReview';
import VisitOurFacility from '../../components/VisitOurFacility';
import TrendingListing from "../../components/TrendingListing";

const OutpatientTreatment = () => {

  return (
    <>
      <Header />

      <PageContentContainer>

        <>
          <section className="dark:bg-background mb-12">
            <div className="relative">
              <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                <h1 className="capitalize text-4xl text-white font-semibold">Outpatient Treatment</h1>
                <p className="text-white mt-2">Helping You Find the Right Program</p>
              </div>
            </div>
          </section>

          <div className="container mx-auto px-5">
            <div className="grid grid-cols-3 gap-7 pb-10">

              <div className="lg:col-span-2 col-span-3">

                <MedicalReview type={"mobile"} />

                <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building.jpg" alt="" /> */}
                  <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      Why Choose Outpatient Treatment Programs for a Lasting Recovery?
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Outpatient treatment is a home-based addiction recovery program that provides structured support, therapy, and medical oversight, allowing patients to maintain daily responsibilities while avoiding full-time care in an inpatient facility.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Outpatient treatment programs for opioid addiction include partial hospitalization programs (PHPs), intensive outpatient programs (IOPs), and standard outpatient programs. PHPs involve long, day-long sessions, while IOPs provide regular support and counseling. Standard outpatient programs are less frequent and are best for later recovery stages. Opioid Rehab's rehab directory offers a comprehensive database of programs tailored to opioid addiction needs. It provides custom search filters, detailed program information, patient reviews and ratings, and licensed and accredited facilities. The directory ensures that all listed programs are reputable and accredited, providing a safe and supportive environment for recovery. In summary, outpatient treatment offers flexibility for recovery, and Opioid Rehab's directory simplifies the process of finding the right program for individual needs.</p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      The Significance of Outpatient Treatment in Addiction Recovery
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Recovering from addiction requires outpatient treatment, especially for those who require supervised care but are unable to attend full-time inpatient programs. Its importance stems from its adaptability, reachability, and capacity to offer continuous assistance, all of which can significantly impact individuals who are trying to continue with their everyday obligations amid therapy.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Outpatient treatment offers several benefits, including flexibility, continuity of care, affordability, real-world application, family involvement, and reduced risk of isolation. It allows patients to maintain their normal routines, such as work, school, or family responsibilities, while receiving care without disrupting their lives. Outpatient treatment also provides a safety net for early recovery, offering counseling, medical check-ins, and peer support. It is generally more affordable than residential programs, making it accessible to a broader range of people, especially those needing long-term care. Patients can apply coping skills and recovery strategies learned during therapy in their daily lives, leading to a more sustainable recovery. </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Family involvement is also encouraged, as family therapy sessions can help build a strong support system. Outpatient treatment also reduces the risk of isolation, as individuals stay connected to their social networks and support systems.</p>
                  </div>
                </div>

                <VisitOurFacility />
                
              </div>

              <div className="lg:col-span-1 col-span-3">

                <MedicalReview type={"desktop"} />

                <RehabSearchForm />

                {/* {featuredListing()} */}
                {<FeaturedListing items={5} />}

              </div>

            </div>
          </div>

        </>

        <TrendingListing />

        <ScrollToTopButton />

        <Footer />
      </PageContentContainer>

    </>
  )
}

export default OutpatientTreatment