import React, { useState, useEffect } from 'react'

var Facilities = [
    "https://www.youtube.com/embed/0njF9gYqByw",
    "https://www.youtube.com/embed/x-F7zdZZ5a8",
    "https://www.youtube.com/embed/gsftLPXSh-4",
    "https://www.youtube.com/embed/9KqDR2WZK_k",
    "https://www.youtube.com/embed/TWT4BmUsrH0",
    "https://www.youtube.com/embed/ko9smCt0isY",
    "https://www.youtube.com/embed/WIJegsXqkFU",
    "https://www.youtube.com/embed/EDqLiuG5CSY",
    "https://www.youtube.com/embed/cvyt2lkK0U8",
    "https://www.youtube.com/embed/wbFTJjmptHs",
];

const VisitOurFacility = () => {
    const [randomFacility, setRandomFacility] = useState({});

    useEffect(() => {
        var item = Facilities[Math.floor(Math.random() * Facilities.length)];
        // console.log(item);
        setRandomFacility(item);
    }, []);

    return (
        <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7 p-6">
            <div className="text-lg font-bold mb-4 text-gray-700 dark:text-gray-100">
                Visit Our Facility
            </div>
            <iframe width={903} height={315} src={randomFacility} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className="rounded-lg w-full" />
        </div>
    )
}

export default VisitOurFacility