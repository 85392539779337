import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Alert from '../../../components/Alert';

import AdminSideBar from '../../../components/AdminSideBar';
import AdminHeader from '../../../components/AdminHeader';

import { Link, useNavigate } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { getCookie, checkRole, base64_encode, base64_decode, openInNewTab, addScript, appendStylesheet, removeScript, removeStylesheet, roleBasedBreadCrumbUrl } from '../../../func';

// const $ = require('jquery');
// $.DataTable = require('datatables.net');

const initialOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENTID,
    //clientId: paypalClientID !== "" ? paypalClientID : process.env.REACT_APP_PAYPAL_CLIENTID,
    currency: process.env.REACT_APP_PAYPAL_CURRENCY,//"USD",
    intent: "capture",
    components: "buttons",
    //"disable-funding": "credit,card,venmo,paylater"
    "disable-funding": "credit,venmo,paylater"
};

var selectedListing = 0;
var currentListing = [];

const MyListing = () => {
    const navigate = useNavigate();

    const [user_id, setUserID] = useState(0);
    const [offset, setOffSet] = useState(0);
    const [limit, setLimit] = useState(0);
    const [loading, setLoading] = useState(true);

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    const [myListing, setMyListing] = useState([]);
    // const [filterText, setFilterText] = useState('');
    // const [filteredListing, setFilteredListing] = useState([]);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        addScript("https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js", "jQueryScript", false, false, "anonymous");
        addScript("https://cdn.datatables.net/1.13.6/js/jquery.dataTables.min.js", "DataTableScript", false, false, "anonymous");
        appendStylesheet("https://cdn.datatables.net/1.13.6/css/jquery.dataTables.min.css", "DataTableStylesheet");

        // const token = getCookie("token");

        // if (token === null) {
        //     // navigate(`/admin/login`, { replace: true });
        //     navigate(`/login`, { replace: true });
        // }
        // else {
        //     const parts = base64_decode(token).split("|");
        //     setUserID(parts[0]);
        //     fetchListing(parts[0]);

        //     //fetchListingTestFunction();
        // }
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            if (checkRole(getCookie("token")) === "admin" || checkRole(getCookie("token")) === "member") {
                const token = getCookie("token");
                const parts = base64_decode(token).split("|");
                setUserID(parts[0]);
                fetchListing(parts[0]);
            }
            else if (checkRole(getCookie("token")) === "author") {
                navigate(`/admin/add_post`, { replace: true });
            }
            else {
                navigate(`/login`, { replace: true });
            }
        }

        return () => {
            removeScript("jQueryScript");
            removeScript("DataTableScript");
            removeStylesheet("DataTableStylesheet");
        }
    }, [])

    useEffect(() => {
        if (myListing.length > 10) {
            // $("#myListingTable").DataTable({
            //     data: this.props.names,
            //     //columns,
            //     ordering: false
            // });

            //$('#myListingTable').DataTable();
            window["bindDataTable"]("myListingTable");
        }
    }, [myListing]);

    // useEffect(() => {
    //     setFilteredListing(myListing.filter(x=>x.title.includes(filterText)));
    // }, [filterText]);    

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 3000);
    }

    const fetchListingTestFunction = () => {
        const json = JSON.stringify({ "state": "AL", "city": "Wetumpka", "offset": 0, "limit": 10000 });
        //const json = JSON.stringify({ "state": "AL", "offset": 0, "limit": 10000 });
        //const json = JSON.stringify({ "offset": 0, "limit": 10000 });

        axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_listing`, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                setMyListing(response.data.listing);
                //setFilteredListing(response.data.listing);
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const fetchListing = (id) => {
        const json = JSON.stringify({ "user_id": id, "offset": offset, "limit": limit });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_user_listing`; //production
        //const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_user_listing_for_update`; //development

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                setMyListing(response.data.listing);
                currentListing = [...response.data.listing];
                //setFilteredListing(response.data.listing);

                // setTimeout(() => {
                //     var tempMyListing = [...myListing];
                //     tempMyListing.filter(l => l.listing_id === selectedListing)[0]["verified"] = 1;
                //     setMyListing(tempMyListing);

                // }, 2000);
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }
            setLoading(false);
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const deleteListing = (listing_id) => {
        const json = JSON.stringify({ "user_id": user_id, "listing_id": listing_id });

        axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=delete_listing`, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                handleMessage({ type: 'success', message: response.data.message, show: true });
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const handleShow = (id) => {
        selectedListing = id;
        setIsVisible(!isVisible);
    };

    const handleHide = () => {
        selectedListing = 0;
        setIsVisible(!isVisible);
    };

    const createOrder = (data, actions, err) => {
        return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
                {
                    description: "Listing Fee",
                    amount: {
                        currency_code: "USD",
                        value: process.env.REACT_APP_LISTING_FEE,
                    },
                },
            ],
        });
    }

    const onApprove = async (data, actions) => {
        // console.log("onApprove data: ", data);
        // console.log("onApprove actions: ", actions);
        const order = await actions.order.capture();
        // console.log("onApprove: ", order);

        //console.log("status", order["purchase_units"][0]["payments"]["captures"][0]["status"])
        //console.log("order", JSON.stringify(order));

        varifyListing(order["purchase_units"][0]["payments"]["captures"][0]["status"], JSON.stringify(order));

        // console.log('order["status"]: ', order["status"])
        // console.log('order["purchase_units"][0]["payments"]["captures"][0]["id"]: ', order["purchase_units"][0]["payments"]["captures"][0]["id"])
        // console.log('order["purchase_units"][0]["payments"]["captures"][0]["status"]: ', order["purchase_units"][0]["payments"]["captures"][0]["status"]);

    }

    const onCancel = async (data) => {
        // console.log("onCancel data: ", data);
        console.log(data);
    }

    const onError = async (err) => {
        // console.log("onError err: ", err);
        console.log(err);
    }

    const varifyListing = (status, payment_response) => {
        // debugger;
        var user_id = base64_decode(getCookie("token")).split("|")[0];

        var json = JSON.stringify({ "listing_id": selectedListing, "payment_status": status, "payment_response": JSON.stringify(JSON.parse(payment_response)), "user_id": user_id });
        var url = `${process.env.REACT_APP_BASE_API}/api.php?action=verify_listing`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {

                // console.log("currentListing", currentListing);
                var tempMyListing = [...currentListing];
                // console.log("tempMyListing", tempMyListing);
                // console.log("tempMyListing.filter(l => l.listing_id === selectedListing)", tempMyListing.filter(l => l.listing_id === selectedListing));
                //tempMyListing.filter(l => l.listing_id === selectedListing)[0]["verified"] = 1;
                tempMyListing.filter(l => l.listing_id === selectedListing)[0]["verified"] = (status.toLowerCase() === "completed" ? 1 : 0);
                setMyListing(tempMyListing);

                handleMessage({ type: 'success', message: response.data.message, show: true });
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }

            selectedListing = 0;
            setIsVisible(false);
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    return (
        <>
            <AdminSideBar />
            <AdminHeader />

            <div className="dark:bg-background lg:ml-64 ml-0 mt-20 min-h-[calc(100vh-80px)] p-7">
                <section className="dark:bg-background">

                    <div className="container mx-auto">

                        <div className="flex justify-between items-center flex-wrap">
                            <div className="flex text-gray-400 text-sm mb-4 items-center">
                                {/* {
                                    base64_decode(getCookie("token")).split("|")[3] === "admin"
                                        ?
                                        (<Link to={"/admin/dashboard"} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                                        :
                                        (<Link to={"/admin/add_listing"} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                                } */}
                                {
                                    getCookie("token") !== null && (<Link to={roleBasedBreadCrumbUrl(base64_decode(getCookie("token")).split("|")[3])} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                                }
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                </svg>
                                <span>My Listing </span>
                            </div>

                            {/* <button type="button" className="flex items-center bg-blue-500 hover:bg-blue-600 text-white rounded px-3 py-2.5 mb-4">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                </svg>
                                Add New Blog
                            </button> */}
                        </div>
                        <Alert type={alert.type} message={alert.message} show={alert.show} />

                        <div className="bg-white shadow-front-2 dark:bg-foreground rounded-lg mb-7 overflow-hidden">
                            <div className="flex flex-col">
                                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                        <div className="overflow-hidden">
                                            <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">

                                                <div className="mb-4">
                                                    <table id="myListingTable" className="min-w-full">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 dark:bg-background items-center font-medium text-gray-700 dark:text-gray-100 px-4 py-3 text-left" data-sortable style={{ width: '5%' }}>ID</th>
                                                                <th scope="col" className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 dark:bg-background items-center font-medium text-gray-700 dark:text-gray-100 px-4 py-3 text-left" data-sortable style={{ width: '35%' }}>Name</th>
                                                                {/* <th scope="col" className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 whitespace-nowrap dark:bg-background font-medium text-gray-700 dark:text-gray-100 px-4 py-3 text-left" data-sortable style={{ width: '12.7441%' }}>
                                                                    <a href="#" className="dataTable-sorter">Category</a>
                                                                </th> */}
                                                                <th scope="col" className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 whitespace-nowrap dark:bg-background font-medium text-gray-700 dark:text-gray-100 px-4 py-3 text-left" data-sortable style={{ width: '17.8828%' }}>Location</th>
                                                                <th scope="col" className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 whitespace-nowrap dark:bg-background font-medium text-gray-700 dark:text-gray-100 px-4 py-3 text-left" data-sortable style={{ width: '11.7163%' }}>Ratings</th>
                                                                <th scope="col" className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 whitespace-nowrap dark:bg-background font-medium text-gray-700 dark:text-gray-100 px-4 py-3 text-left" data-sortable style={{ width: '11.7163%' }}>Reviews</th>
                                                                <th scope="col" className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 whitespace-nowrap dark:bg-background font-medium text-gray-700 dark:text-gray-100 px-4 py-3 text-left" data-sortable style={{ width: '11.7163%' }}>Verified</th>
                                                                <th scope="col" className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 whitespace-nowrap dark:bg-background font-medium text-gray-700 dark:text-gray-100 px-4 pb-3 text-left" data-sortable style={{ width: '13.9774%' }}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        {!loading ?
                                                            (<tbody>
                                                                {/* <tr className="align-middle hover:bg-gray-50 dark:hover:bg-background">
                                                                <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-medium text-gray-700 dark:text-gray-300 px-6 py-3">
                                                                    <div className="flex items-center mr-10">
                                                                        <img className="w-10 h-10 object-cover rounded-lg mr-4 border border-white" src="/img/Image/landing-page-image/building-2.jpg" alt="" />
                                                                        Premium Duplex Apartment
                                                                    </div>
                                                                </td>
                                                                <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-regular text-gray-500 dark:text-gray-300 px-6 py-3">
                                                                    Apartment
                                                                </td>
                                                                <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-regular text-gray-500 dark:text-gray-300 px-6 py-3">
                                                                    Manhattan, USA
                                                                </td>
                                                                <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-medium text-gray-500 dark:text-gray-300 px-6 py-3">
                                                                    <div className="flex items-center">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                                                                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                                        </svg>
                                                                        4.75
                                                                    </div>
                                                                </td>
                                                                <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-medium text-gray-700 dark:text-gray-300 px-6 py-3">
                                                                    <div className="flex items-center">
                                                                        <button type="button" className="border mr-2 border-gray-200 hover:bg-blue-500 dark:hover:border-blue-500 hover:text-white text-gray-700 dark:text-gray-300 dark:border-gray-800 rounded-full w-8 h-8 flex justify-center items-center">
                                                                            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M14.1767 2.5903C14.964 1.80323 16.2424 1.80323 17.0296 2.5903L17.4107 2.97163C18.1979 3.75885 18.1979 5.03604 17.4107 5.82326L10.4705 12.7603C10.206 13.0279 9.86907 13.1854 9.50065 13.2987L6.64776 13.9568C6.47772 13.9946 6.30138 13.9442 6.17857 13.8214C6.05577 13.6986 6.00538 13.5223 6.04317 13.3522L6.70129 10.4994C6.78631 10.1309 6.97209 9.794 7.23975 9.50116L14.1767 2.5903ZM16.3148 3.30289C15.9212 2.90928 15.2852 2.90928 14.8915 3.30289L14.0476 4.1449L15.8551 5.95236L16.6959 5.10846C17.0895 4.7158 17.0895 4.07783 16.6959 3.68422L16.3148 3.30289ZM7.68374 10.7261L7.20511 12.7949L9.24559 12.3163C9.45657 12.2753 9.62661 12.1809 9.75886 12.0203L15.1435 6.66401L13.336 4.8575L7.9514 10.2411C7.81914 10.3734 7.72468 10.5434 7.68374 10.7261ZM8.54969 3.89299C8.82679 3.89299 9.05351 4.11845 9.05351 4.39681C9.05351 4.67518 8.82679 4.90064 8.54969 4.90064H4.51911C3.68434 4.90064 3.00764 5.57765 3.00764 6.4121V15.4809C3.00764 16.3153 3.68434 16.9924 4.51911 16.9924H13.5879C14.4224 16.9924 15.0994 16.3153 15.0994 15.4809V11.4503C15.0994 11.1732 15.3261 10.9465 15.6032 10.9465C15.8803 10.9465 16.107 11.1732 16.107 11.4503V15.4809C16.107 16.8727 14.9797 18 13.5879 18H4.51911C3.12793 18 2 16.8727 2 15.4809V6.4121C2 5.02092 3.12793 3.89299 4.51911 3.89299H8.54969Z" fill="currentColor" />
                                                                            </svg>
                                                                        </button>
                                                                        <button type="button" className="border mr-2 border-gray-200 hover:bg-blue-500 dark:hover:border-blue-500 hover:text-white text-gray-700 dark:text-gray-300 dark:border-gray-800 rounded-full w-8 h-8 flex justify-center items-center">
                                                                            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M7.5 14.5C7.5 14.775 7.275 15 7 15C6.725 15 6.5 14.775 6.5 14.5V7.5C6.5 7.225 6.725 7 7 7C7.275 7 7.5 7.225 7.5 7.5V14.5ZM10.5 14.5C10.5 14.775 10.275 15 10 15C9.725 15 9.5 14.775 9.5 14.5V7.5C9.5 7.225 9.725 7 10 7C10.275 7 10.5 7.225 10.5 7.5V14.5ZM13.5 14.5C13.5 14.775 13.275 15 13 15C12.725 15 12.5 14.775 12.5 14.5V7.5C12.5 7.225 12.725 7 13 7C13.275 7 13.5 7.225 13.5 7.5V14.5ZM12.6906 2.705L13.5281 4H16.5C16.775 4 17 4.22375 17 4.5C17 4.77625 16.775 5 16.5 5H16V15.5C16 16.8813 14.8813 18 13.5 18H6.5C5.11937 18 4 16.8813 4 15.5V5H3.5C3.22387 5 3 4.77625 3 4.5C3 4.22375 3.22387 4 3.5 4H6.47187L7.28125 2.705C7.55625 2.26644 8.0375 2 8.55312 2H11.4469C11.9625 2 12.4438 2.26644 12.6906 2.705ZM7.65312 4H12.3469L11.8687 3.235C11.7781 3.08875 11.6187 3 11.4469 3H8.55312C8.38125 3 8.22187 3.08875 8.13125 3.235L7.65312 4ZM5 15.5C5 16.3281 5.67156 17 6.5 17H13.5C14.3281 17 15 16.3281 15 15.5V5H5V15.5Z" fill="currentColor" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr> */}
                                                                {myListing.length > 0 ? myListing.map((business, index) => (
                                                                    <tr className="align-middle hover:bg-gray-50 dark:hover:bg-background" key={index}>
                                                                        <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-regular text-gray-500 dark:text-gray-300 px-4 py-3">
                                                                            {`${business.listing_id}`}
                                                                        </td>
                                                                        <td className="border-b border-gray-200 dark:border-gray-900 text-sm font-medium text-gray-700 dark:text-gray-300 px-4 py-3">
                                                                            <div className="flex items-center">
                                                                                {(business.images !== "" && business.images !== undefined) && (<img className="w-20 h-20 object-cover rounded-lg mr-2 border border-white" src={`${process.env.REACT_APP_BASE_API}${JSON.parse(business.images)[0]["base64"]}`} alt="" />)}
                                                                                <div className="flex" style={{ flexDirection: "column" }}>
                                                                                    <div className='text-blue-500 hover:text-blue-500' onClick={() => openInNewTab(`/details/${base64_encode(business.listing_id.toString())}/${business.alias}`)} style={{ cursor: "pointer" }}>{business.title}</div>
                                                                                    <div className="text-gray-500 dark:text-gray-300">
                                                                                        {business.address}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        {/* <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-regular text-gray-500 dark:text-gray-300 px-4 py-3">
                                                                        Apartment
                                                                    </td> */}
                                                                        <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-regular text-gray-500 dark:text-gray-300 px-4 py-3">
                                                                            {`${business.city}, ${business.state}`}{/* Manhattan, USA */}
                                                                        </td>
                                                                        <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-medium text-gray-500 dark:text-gray-300 px-4 py-3">
                                                                            <div className="flex items-center" style={{ justifyContent: "space-around" }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                                                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                                                </svg>
                                                                                {business["rating"]}
                                                                            </div>
                                                                        </td>
                                                                        <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-medium text-gray-500 dark:text-gray-300 px-4 py-3">
                                                                            <div className="items-center text-center">
                                                                                {/* <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                                                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                                            </svg> */}
                                                                                {business["reviews"]}
                                                                            </div>
                                                                        </td>
                                                                        <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-medium text-gray-500 dark:text-gray-300 px-4 py-3">
                                                                            {business["verified"].toString() === "1" ?
                                                                                (<div className="inline-flex items-center bg-green-100 text-xs font-medium text-green-500 px-3 py-1 rounded mr-3">
                                                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                                                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                                            </svg> */}
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-3 h-3 mr-2" viewBox="0 0 20 20">
                                                                                        <path fillRule="evenodd" d="M6.267 3.455a3.066 3.066 0 0 0 1.745-.723 3.066 3.066 0 0 1 3.976 0 3.066 3.066 0 0 0 1.745.723 3.066 3.066 0 0 1 2.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 0 1 0 3.976 3.066 3.066 0 0 0-.723 1.745 3.066 3.066 0 0 1-2.812 2.812 3.066 3.066 0 0 0-1.745.723 3.066 3.066 0 0 1-3.976 0 3.066 3.066 0 0 0-1.745-.723 3.066 3.066 0 0 1-2.812-2.812 3.066 3.066 0 0 0-.723-1.745 3.066 3.066 0 0 1 0-3.976 3.066 3.066 0 0 0 .723-1.745 3.066 3.066 0 0 1 2.812-2.812zm7.44 5.252a1 1 0 0 0-1.414-1.414L9 10.586 7.707 9.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4z" clipRule="evenodd" />
                                                                                    </svg>
                                                                                    {business["verified"].toString() === "1" && "Verified"}
                                                                                </div>)
                                                                                :
                                                                                (<button className="inline-flex items-center bg-red-100 text-xs font-medium text-red-500 px-3 py-1 rounded mr-3" data-modal-target="paypal-payment-modal" data-modal-toggle="paypal-payment-modal" onClick={() => handleShow(business.listing_id)}>
                                                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-3 h-3 mr-2" viewBox="0 0 20 20">
                                                                                        <path fillRule="evenodd" d="M6.267 3.455a3.066 3.066 0 0 0 1.745-.723 3.066 3.066 0 0 1 3.976 0 3.066 3.066 0 0 0 1.745.723 3.066 3.066 0 0 1 2.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 0 1 0 3.976 3.066 3.066 0 0 0-.723 1.745 3.066 3.066 0 0 1-2.812 2.812 3.066 3.066 0 0 0-1.745.723 3.066 3.066 0 0 1-3.976 0 3.066 3.066 0 0 0-1.745-.723 3.066 3.066 0 0 1-2.812-2.812 3.066 3.066 0 0 0-.723-1.745 3.066 3.066 0 0 1 0-3.976 3.066 3.066 0 0 0 .723-1.745 3.066 3.066 0 0 1 2.812-2.812zm7.44 5.252a1 1 0 0 0-1.414-1.414L9 10.586 7.707 9.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4z" clipRule="evenodd" />
                                                                                    </svg> */}
                                                                                    {business["verified"].toString() === "0" && "Verify Now"}
                                                                                </button>)
                                                                            }
                                                                        </td>
                                                                        <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-medium text-gray-700 dark:text-gray-300 px-4 py-3">
                                                                            <div className="flex items-center">
                                                                                <button type="button" className="border mr-2 border-gray-200 hover:bg-blue-500 dark:hover:border-blue-500 hover:text-white text-gray-700 dark:text-gray-300 dark:border-gray-800 rounded-full w-8 h-8 flex justify-center items-center" onClick={() => openInNewTab(`/admin/add_review/${business.listing_id}`)} title={"Add Reviews"}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                                                                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                                                    </svg>
                                                                                </button>
                                                                                <button type="button" className="border mr-2 border-gray-200 hover:bg-blue-500 dark:hover:border-blue-500 hover:text-white text-gray-700 dark:text-gray-300 dark:border-gray-800 rounded-full w-8 h-8 flex justify-center items-center" onClick={() => openInNewTab(`/admin/edit_listing/${business.listing_id}`)} title={"Edit Listing"}>
                                                                                    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M14.1767 2.5903C14.964 1.80323 16.2424 1.80323 17.0296 2.5903L17.4107 2.97163C18.1979 3.75885 18.1979 5.03604 17.4107 5.82326L10.4705 12.7603C10.206 13.0279 9.86907 13.1854 9.50065 13.2987L6.64776 13.9568C6.47772 13.9946 6.30138 13.9442 6.17857 13.8214C6.05577 13.6986 6.00538 13.5223 6.04317 13.3522L6.70129 10.4994C6.78631 10.1309 6.97209 9.794 7.23975 9.50116L14.1767 2.5903ZM16.3148 3.30289C15.9212 2.90928 15.2852 2.90928 14.8915 3.30289L14.0476 4.1449L15.8551 5.95236L16.6959 5.10846C17.0895 4.7158 17.0895 4.07783 16.6959 3.68422L16.3148 3.30289ZM7.68374 10.7261L7.20511 12.7949L9.24559 12.3163C9.45657 12.2753 9.62661 12.1809 9.75886 12.0203L15.1435 6.66401L13.336 4.8575L7.9514 10.2411C7.81914 10.3734 7.72468 10.5434 7.68374 10.7261ZM8.54969 3.89299C8.82679 3.89299 9.05351 4.11845 9.05351 4.39681C9.05351 4.67518 8.82679 4.90064 8.54969 4.90064H4.51911C3.68434 4.90064 3.00764 5.57765 3.00764 6.4121V15.4809C3.00764 16.3153 3.68434 16.9924 4.51911 16.9924H13.5879C14.4224 16.9924 15.0994 16.3153 15.0994 15.4809V11.4503C15.0994 11.1732 15.3261 10.9465 15.6032 10.9465C15.8803 10.9465 16.107 11.1732 16.107 11.4503V15.4809C16.107 16.8727 14.9797 18 13.5879 18H4.51911C3.12793 18 2 16.8727 2 15.4809V6.4121C2 5.02092 3.12793 3.89299 4.51911 3.89299H8.54969Z" fill="currentColor" />
                                                                                    </svg>
                                                                                </button>
                                                                                <button type="button" className="border mr-2 border-gray-200 hover:bg-blue-500 dark:hover:border-blue-500 hover:text-white text-gray-700 dark:text-gray-300 dark:border-gray-800 rounded-full w-8 h-8 flex justify-center items-center" onClick={() => deleteListing(business.listing_id)} title={"Delete Listing"}>
                                                                                    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M7.5 14.5C7.5 14.775 7.275 15 7 15C6.725 15 6.5 14.775 6.5 14.5V7.5C6.5 7.225 6.725 7 7 7C7.275 7 7.5 7.225 7.5 7.5V14.5ZM10.5 14.5C10.5 14.775 10.275 15 10 15C9.725 15 9.5 14.775 9.5 14.5V7.5C9.5 7.225 9.725 7 10 7C10.275 7 10.5 7.225 10.5 7.5V14.5ZM13.5 14.5C13.5 14.775 13.275 15 13 15C12.725 15 12.5 14.775 12.5 14.5V7.5C12.5 7.225 12.725 7 13 7C13.275 7 13.5 7.225 13.5 7.5V14.5ZM12.6906 2.705L13.5281 4H16.5C16.775 4 17 4.22375 17 4.5C17 4.77625 16.775 5 16.5 5H16V15.5C16 16.8813 14.8813 18 13.5 18H6.5C5.11937 18 4 16.8813 4 15.5V5H3.5C3.22387 5 3 4.77625 3 4.5C3 4.22375 3.22387 4 3.5 4H6.47187L7.28125 2.705C7.55625 2.26644 8.0375 2 8.55312 2H11.4469C11.9625 2 12.4438 2.26644 12.6906 2.705ZM7.65312 4H12.3469L11.8687 3.235C11.7781 3.08875 11.6187 3 11.4469 3H8.55312C8.38125 3 8.22187 3.08875 8.13125 3.235L7.65312 4ZM5 15.5C5 16.3281 5.67156 17 6.5 17H13.5C14.3281 17 15 16.3281 15 15.5V5H5V15.5Z" fill="currentColor" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )) :
                                                                    (<tr><td colSpan={5}><div className="text-center mt-4">No item in the list</div></td></tr>)
                                                                }
                                                            </tbody>)
                                                            :
                                                            (
                                                                <tbody>
                                                                    {
                                                                        [...Array(5)].map((u, index) => (<tr key={index}>
                                                                            <td><div className="w-full h-8 skeleton mt-2"></div></td>
                                                                            <td><div className="w-full h-8 skeleton mt-2"></div></td>
                                                                            <td><div className="w-full h-8 skeleton mt-2"></div></td>
                                                                            <td><div className="w-full h-8 skeleton mt-2"></div></td>
                                                                            <td><div className="w-full h-8 skeleton mt-2"></div></td>
                                                                            <td><div className="w-full h-8 skeleton mt-2"></div></td>
                                                                            <td><div className="w-full h-8 skeleton mt-2"></div></td>
                                                                        </tr>))
                                                                    }
                                                                </tbody>
                                                            )
                                                        }
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>
            </div>

            <div tabIndex={-1} aria-hidden="true" className={`${isVisible ? "flex" : "hidden"} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bottom-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`} style={{ backgroundColor: "#aaaaaabb" }}>
                <div className="relative p-4 w-full max-w-md max-h-full">
                    {/* Modal content */}
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        {/* Modal header */}
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-md font-semibold text-gray-900 dark:text-white">
                                Pay Now To Verify Your Listing
                            </h3>
                            <button type="button" className="end-2.5 bg-red-100 text-red-500 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="paypal-payment-modal" onClick={() => handleHide()}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                {/* <span className="sr-only">Close modal</span> */}
                            </button>
                        </div>
                        {/* Modal body */}
                        <div className="p-4 md:p-5">
                            {/* <div className="alert alert-info" role="alert">
                                One time verification fee is <b>${process.env.REACT_APP_LISTING_FEE}</b>
                            </div> */}
                            <div className='mb-4 text-center text-lg'>
                                One time claiming fee is <b>${process.env.REACT_APP_LISTING_FEE}</b>
                            </div>
                            <PayPalScriptProvider options={initialOptions}>
                                <PayPalButtons
                                    style={{ layout: "vertical", tagline: false }}
                                    createOrder={createOrder}
                                    onApprove={onApprove}
                                    onError={onError}
                                    onCancel={onCancel}
                                />
                            </PayPalScriptProvider>
                            {/* <form className="space-y-4" action="#">
                                <div>
                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                    <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" required />
                                </div>
                                <div>
                                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your password</label>
                                    <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                                </div>
                                <div className="flex justify-between">
                                    <div className="flex items-start">
                                        <div className="flex items-center h-5">
                                            <input id="remember" type="checkbox" defaultValue className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" required />
                                        </div>
                                        <label htmlFor="remember" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Remember me</label>
                                    </div>
                                    <a href="#" className="text-sm text-blue-700 hover:underline dark:text-blue-500">Lost Password?</a>
                                </div>
                                <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login to your account</button>
                                <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                                    Not registered? <a href="#" className="text-blue-700 hover:underline dark:text-blue-500">Create account</a>
                                </div>
                            </form> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyListing