import React, { useState, useEffect } from 'react'
import DesktopHeroForm from '../DesktopHeroForm'
import MobileHeroForm from '../MobileHeroForm'

import { Link, useNavigate } from 'react-router-dom'
import ContactUsHeroForm from '../ContactUsHeroForm';
import AboutUsHero from '../AboutUsHero';

import { getCookie, checkRole, eraseCookie } from '../../func';

const HomeNav = (page) => {
  const navigate = useNavigate();

  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    if (getCookie("token") !== undefined && getCookie("token") !== null) {
      //user is loggedin
      setUserLoggedIn(true);
      setUserRole(checkRole(getCookie("token")));
    }

    // Respond to the `storage` event
    function storageEventHandler(event) {
      if (localStorage.getItem("isLoggedIn") !== null) {
        setUserLoggedIn(true);
        setUserRole(checkRole(getCookie("token")));
      }
      else {
        setUserLoggedIn(false);
        setUserRole('');
      }
    }
    // Hook up the event handler
    window.addEventListener("storage", storageEventHandler);
    return () => {
      // Remove the handler when the component unmounts
      window.removeEventListener("storage", storageEventHandler);
    };
  }, [])

  const logout = () => {
    setTimeout(() => {
      localStorage.removeItem("isLoggedIn");
      eraseCookie("token");
      // navigate(`/admin/login`, { replace: true });
      navigate(`/login`, { replace: true });
    }, 500);
  }

  const CreateRoleBasedNavigation = () => {
    if (userRole === "admin") {
      return <>
        <li className="list-none">
          <div className="relative [&>div]:hover:visible [&>div]:hover:pt-3 [&>div]:hover:h-full [&>div]:hover:opacity-100 [&>div]:hover:top-auto [&>div]:hover:transition-all [&>div]:hover:z-10 pr-9">
            <div className="link hover:text-blue-500 flex items-center" style={{ paddingTop: "0px" }}>
              <div className="flex items-center">
                <div className="relative lg:mr-3 mr-3">
                  <img className="w-8 h-8 rounded-full border border-white" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAMAAAANmfvwAAAAMFBMVEX////S19vR1try8vIAAADQ1dnZ3eDf4uXl5+rW2t75+vrt7/D19vfn6uzx8vQYGShNh6tnAAAA5UlEQVQ4jd2SSQLDIAhFhQZn7f1vWzWolHTYdFVW4ecxijG/t1qcta7Ud/+jo2GIRC6+IjwhEPjiQ/tA8lciEACdFart30ETgE3N08uNQVA5GoEid4DmP+XxLUokMcZhF0RM7ARQVIhUTgGdRoQykgDajdiBAE0/XZHASJrNsi/ajRw1G3aMiKfJjMxmuDCWjejajADsGQM8I1xoNzeT7ELcrhhp5l3tVo5ZM21hTaCRtAS17q1k3cpeVFBp5cOWcQyw1ltpHINYVB+BIEmhXbJ8s7GrYpT5y/H+j92+mjma3Y9P9gDGnQaRgDV0MgAAAABJRU5ErkJggg==" data-dropdown-button="true" alt="" />
                  <div className="w-2 h-2 bg-green-500 rounded-full border absolute bottom-0 right-0"></div>
                </div>
                My Account
              </div>
              <svg className="w-3 h-auto ml-2" width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
              </svg>
            </div>
            <div className="absolute top-9 invisible opacity-0 whitespace-nowrap pt-0">
              <div className="max-w-sm shadow-front-3 rounded-lg whitespace-nowrap bg-white dark:bg-foreground dark:text-gray-300 text-gray-700" style={{ minWidth: "150px" }}>
                <div className="dropdown-links">
                  <ul className="p-4">
                    <li>
                      <Link to={"/admin/dashboard"} className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                        <svg className="w-4 h-4 mr-2" width={20} height={20} fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fill="currentColor" d="M7.143 2c.946 0 1.714.746 1.714 1.667v5.555c0 .92-.768 1.667-1.714 1.667H3.714C2.768 10.889 2 10.142 2 9.222V3.667C2 2.747 2.768 2 3.714 2h3.429Zm0 11.111c.946 0 1.714.747 1.714 1.667v1.11c0 .921-.768 1.668-1.714 1.668H3.714c-.946 0-1.714-.747-1.714-1.667v-1.111c0-.92.768-1.667 1.714-1.667h3.429Z" />
                          <path fill="#B2DDFF" d="M16.286 2C17.232 2 18 2.746 18 3.667v1.11c0 .92-.768 1.667-1.714 1.667h-3.429c-.946 0-1.714-.746-1.714-1.666V3.667c0-.92.768-1.667 1.714-1.667h3.429Zm0 6.667c.946 0 1.714.746 1.714 1.666v5.556c0 .92-.768 1.667-1.714 1.667h-3.429c-.946 0-1.714-.747-1.714-1.667v-5.556c0-.92.768-1.666 1.714-1.666h3.429Z" />
                        </svg>
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link to={"/admin/profile"} className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                        <svg className="w-4 h-4 mr-2" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.8296 11.6H8.57036C5.49461 11.6 3 13.988 3 16.9331C3 17.5221 3.49886 18 4.11407 18H16.2866C16.9018 18.0009 17.4 17.5239 17.4 16.9331C17.4 13.988 14.9057 11.6 11.8296 11.6Z" fill="currentColor" />
                          <path d="M14.2 6C14.2 8.20906 12.4094 10 10.2 10C7.99064 10 6.20001 8.20937 6.20001 6C6.20001 3.79063 7.99095 2 10.2 2C12.4094 2 14.2 3.79094 14.2 6Z" fill="#D0D5DD" />
                        </svg>
                        My Profile
                      </Link>
                    </li>
                    <li>
                      <Link to={"/admin/change_password"} className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                        <svg className="mr-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                        </svg>
                        Change Password
                      </Link>
                    </li>
                    <li>
                      <div className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3" style={{ cursor: "pointer" }} onClick={() => logout()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                        Sign Out
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>
      </>
    }
    else if (userRole === "member") {
      return <>
        <li className="list-none">
          <div className="relative [&>div]:hover:visible [&>div]:hover:pt-3 [&>div]:hover:h-full [&>div]:hover:opacity-100 [&>div]:hover:top-auto [&>div]:hover:transition-all [&>div]:hover:z-10 pr-9">
            <div className="link hover:text-blue-500 flex items-center" style={{ paddingTop: "0px" }}>
              <div className="flex items-center">
                <div className="relative lg:mr-3 mr-3">
                  <img className="w-8 h-8 rounded-full border border-white" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAMAAAANmfvwAAAAMFBMVEX////S19vR1try8vIAAADQ1dnZ3eDf4uXl5+rW2t75+vrt7/D19vfn6uzx8vQYGShNh6tnAAAA5UlEQVQ4jd2SSQLDIAhFhQZn7f1vWzWolHTYdFVW4ecxijG/t1qcta7Ud/+jo2GIRC6+IjwhEPjiQ/tA8lciEACdFart30ETgE3N08uNQVA5GoEid4DmP+XxLUokMcZhF0RM7ARQVIhUTgGdRoQykgDajdiBAE0/XZHASJrNsi/ajRw1G3aMiKfJjMxmuDCWjejajADsGQM8I1xoNzeT7ELcrhhp5l3tVo5ZM21hTaCRtAS17q1k3cpeVFBp5cOWcQyw1ltpHINYVB+BIEmhXbJ8s7GrYpT5y/H+j92+mjma3Y9P9gDGnQaRgDV0MgAAAABJRU5ErkJggg==" data-dropdown-button="true" alt="" />
                  <div className="w-2 h-2 bg-green-500 rounded-full border absolute bottom-0 right-0"></div>
                </div>
                My Account
              </div>
              <svg className="w-3 h-auto ml-2" width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
              </svg>
            </div>
            <div className="absolute top-9 invisible opacity-0 whitespace-nowrap pt-0">
              <div className="max-w-sm shadow-front-3 rounded-lg whitespace-nowrap bg-white dark:bg-foreground dark:text-gray-300 text-gray-700" style={{ minWidth: "150px" }}>
                <div className="dropdown-links">
                  <ul className="p-4">
                    <li>
                      <Link to={"/admin/add_listing"} className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                        <svg className="w-4 h-4 mr-2" width={20} height={20} fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fill="currentColor" d="M7.143 2c.946 0 1.714.746 1.714 1.667v5.555c0 .92-.768 1.667-1.714 1.667H3.714C2.768 10.889 2 10.142 2 9.222V3.667C2 2.747 2.768 2 3.714 2h3.429Zm0 11.111c.946 0 1.714.747 1.714 1.667v1.11c0 .921-.768 1.668-1.714 1.668H3.714c-.946 0-1.714-.747-1.714-1.667v-1.111c0-.92.768-1.667 1.714-1.667h3.429Z" />
                          <path fill="#B2DDFF" d="M16.286 2C17.232 2 18 2.746 18 3.667v1.11c0 .92-.768 1.667-1.714 1.667h-3.429c-.946 0-1.714-.746-1.714-1.666V3.667c0-.92.768-1.667 1.714-1.667h3.429Zm0 6.667c.946 0 1.714.746 1.714 1.666v5.556c0 .92-.768 1.667-1.714 1.667h-3.429c-.946 0-1.714-.747-1.714-1.667v-5.556c0-.92.768-1.666 1.714-1.666h3.429Z" />
                        </svg>
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link to={"/admin/profile"} className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                        <svg className="w-4 h-4 mr-2" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.8296 11.6H8.57036C5.49461 11.6 3 13.988 3 16.9331C3 17.5221 3.49886 18 4.11407 18H16.2866C16.9018 18.0009 17.4 17.5239 17.4 16.9331C17.4 13.988 14.9057 11.6 11.8296 11.6Z" fill="currentColor" />
                          <path d="M14.2 6C14.2 8.20906 12.4094 10 10.2 10C7.99064 10 6.20001 8.20937 6.20001 6C6.20001 3.79063 7.99095 2 10.2 2C12.4094 2 14.2 3.79094 14.2 6Z" fill="#D0D5DD" />
                        </svg>
                        My Profile
                      </Link>
                    </li>
                    <li>
                      <Link to={"/admin/change_password"} className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                        <svg className="mr-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                        </svg>
                        Change Password
                      </Link>
                    </li>
                    <li>
                      <div className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3" style={{ cursor: "pointer" }} onClick={() => logout()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                        Sign Out
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>
      </>
    }
    else if (userRole === "author") {
      return <>
        <li className="list-none">
          <div className="relative [&>div]:hover:visible [&>div]:hover:pt-3 [&>div]:hover:h-full [&>div]:hover:opacity-100 [&>div]:hover:top-auto [&>div]:hover:transition-all [&>div]:hover:z-10 pr-9">
            <div className="link hover:text-blue-500 flex items-center" style={{ paddingTop: "0px" }}>
              <div className="flex items-center">
                <div className="relative lg:mr-3 mr-3">
                  <img className="w-8 h-8 rounded-full border border-white" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAMAAAANmfvwAAAAMFBMVEX////S19vR1try8vIAAADQ1dnZ3eDf4uXl5+rW2t75+vrt7/D19vfn6uzx8vQYGShNh6tnAAAA5UlEQVQ4jd2SSQLDIAhFhQZn7f1vWzWolHTYdFVW4ecxijG/t1qcta7Ud/+jo2GIRC6+IjwhEPjiQ/tA8lciEACdFart30ETgE3N08uNQVA5GoEid4DmP+XxLUokMcZhF0RM7ARQVIhUTgGdRoQykgDajdiBAE0/XZHASJrNsi/ajRw1G3aMiKfJjMxmuDCWjejajADsGQM8I1xoNzeT7ELcrhhp5l3tVo5ZM21hTaCRtAS17q1k3cpeVFBp5cOWcQyw1ltpHINYVB+BIEmhXbJ8s7GrYpT5y/H+j92+mjma3Y9P9gDGnQaRgDV0MgAAAABJRU5ErkJggg==" data-dropdown-button="true" alt="" />
                  <div className="w-2 h-2 bg-green-500 rounded-full border absolute bottom-0 right-0"></div>
                </div>
                My Account
              </div>
              <svg className="w-3 h-auto ml-2" width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
              </svg>
            </div>
            <div className="absolute top-9 invisible opacity-0 whitespace-nowrap pt-0">
              <div className="max-w-sm shadow-front-3 rounded-lg whitespace-nowrap bg-white dark:bg-foreground dark:text-gray-300 text-gray-700" style={{ minWidth: "150px" }}>
                <div className="dropdown-links">
                  <ul className="p-4">
                    <li>
                      <Link to={"/admin/add_post"} className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                        <svg className="w-4 h-4 mr-2" width={20} height={20} fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fill="currentColor" d="M7.143 2c.946 0 1.714.746 1.714 1.667v5.555c0 .92-.768 1.667-1.714 1.667H3.714C2.768 10.889 2 10.142 2 9.222V3.667C2 2.747 2.768 2 3.714 2h3.429Zm0 11.111c.946 0 1.714.747 1.714 1.667v1.11c0 .921-.768 1.668-1.714 1.668H3.714c-.946 0-1.714-.747-1.714-1.667v-1.111c0-.92.768-1.667 1.714-1.667h3.429Z" />
                          <path fill="#B2DDFF" d="M16.286 2C17.232 2 18 2.746 18 3.667v1.11c0 .92-.768 1.667-1.714 1.667h-3.429c-.946 0-1.714-.746-1.714-1.666V3.667c0-.92.768-1.667 1.714-1.667h3.429Zm0 6.667c.946 0 1.714.746 1.714 1.666v5.556c0 .92-.768 1.667-1.714 1.667h-3.429c-.946 0-1.714-.747-1.714-1.667v-5.556c0-.92.768-1.666 1.714-1.666h3.429Z" />
                        </svg>
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link to={"/admin/profile"} className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                        <svg className="w-4 h-4 mr-2" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.8296 11.6H8.57036C5.49461 11.6 3 13.988 3 16.9331C3 17.5221 3.49886 18 4.11407 18H16.2866C16.9018 18.0009 17.4 17.5239 17.4 16.9331C17.4 13.988 14.9057 11.6 11.8296 11.6Z" fill="currentColor" />
                          <path d="M14.2 6C14.2 8.20906 12.4094 10 10.2 10C7.99064 10 6.20001 8.20937 6.20001 6C6.20001 3.79063 7.99095 2 10.2 2C12.4094 2 14.2 3.79094 14.2 6Z" fill="#D0D5DD" />
                        </svg>
                        My Profile
                      </Link>
                    </li>
                    <li>
                      <Link to={"/admin/change_password"} className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                        <svg className="mr-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                        </svg>
                        Change Password
                      </Link>
                    </li>
                    <li>
                      <div className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3" style={{ cursor: "pointer" }} onClick={() => logout()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                        Sign Out
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>
      </>
    }
  }

  return (
    <>
      <header className="bg-no-repeat bg-cover text-white dark:bg-transparent sticky-header-first-content" style={{ backgroundImage: 'linear-gradient(177.37deg,#000000 2.19%,rgba(75, 82, 93, 0) 77.2%),url("img/Image/landing-page-image/meeting.webp")' }}>
        {/* style={{ backgroundImage: 'linear-gradient(177.37deg,#000000 2.19%,rgba(75, 82, 93, 0) 77.2%),url("img/bg-header.jpg")' }} */}
        <div className="w-full navbar h-20 flex items-center hero-sticky-header left-0 dark:text-gray-100">
          <nav className="container mx-auto lg:px-0 px-5 flex items-center justify-between transition-all">
            <button type="button" className="lg:hidden block menu-btn" aria-label="Mobile Menu Button">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
            <Link to={"/"} className="flex items-center" aria-label="Opioid-rehab logo">
              <img className="w-13 h-13 mr-3" src="/img/OR-logo.webp" alt="" width={50} height={50} />
            </Link>
            <div className="hidden lg:flex items-center">
              <ul className="flex items-center">

                <li className="list-none">
                  <div className="relative [&>div]:hover:visible [&>div]:hover:pt-3 [&>div]:hover:h-full [&>div]:hover:opacity-100 [&>div]:hover:top-auto [&>div]:hover:transition-all [&>div]:hover:z-10 pr-9">
                    <Link to={"/opioid-addiction"} className="link hover:text-blue-500 flex items-center">
                      Opioid Addiction
                      <svg className="w-3 h-auto ml-2" width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                      </svg>
                    </Link>
                    <div className="absolute top-9 invisible opacity-0 whitespace-nowrap pt-0">
                      <div className="max-w-sm shadow-front-3 rounded-lg whitespace-nowrap bg-white dark:bg-foreground dark:text-gray-300 text-gray-700">
                        <div className="dropdown-links">
                          <ul className="p-4">
                            <li>
                              <Link to={"/signs-of-opioid-addiction"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Signs of Opioid Addiction</Link>
                            </li>
                            <li>
                              <Link to={"/effects-of-opioid-dependency"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Effects of Opioid Dependency</Link>
                            </li>
                            <li>
                              <Link to={"/signs-of-opioid-overdose"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Signs of Opioid Overdose</Link>
                            </li>
                            <li>
                              <Link to={"/opioid-addiction-risk-factors"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Opioid Addiction Risk Factors</Link>
                            </li>
                            <li>
                              <Link to={"/am-i-addicted-to-opioids"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Am I Addicted to Opioids? </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-none">
                  <div className="relative [&>div]:hover:visible [&>div]:hover:pt-3 [&>div]:hover:h-full [&>div]:hover:opacity-100 [&>div]:hover:top-auto [&>div]:hover:transition-all [&>div]:hover:z-10 pr-9">
                    <Link to={"/find-opioid-treatment"} className="link hover:text-blue-500 flex items-center">
                      Find Opioid Treatment
                      <svg className="w-3 h-auto ml-2" width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                      </svg>
                    </Link>
                    <div className="absolute top-9 invisible opacity-0 whitespace-nowrap pt-0">
                      <div className="max-w-sm shadow-front-3 rounded-lg whitespace-nowrap bg-white dark:bg-foreground dark:text-gray-300 text-gray-700">
                        <div className="dropdown-links">
                          <ul className="p-4">
                            <li>
                              <Link to={"/detox"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Detox</Link>
                            </li>
                            <li>
                              <Link to={"/residential-treatment"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Residential Treatment</Link>
                            </li>
                            <li>
                              <Link to={"/medication-assisted-treatment"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Medication-Assisted Treatment</Link>
                            </li>
                            <li>
                              <Link to={"/virtual-treatment"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Virtual Treatment</Link>
                            </li>
                            <li>
                              <Link to={"/outpatient-treatment"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Outpatient Treatment</Link>
                            </li>
                            <li>
                              <Link to={"/paying-for-treatment"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Paying For Treatment</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-none">
                  <div className="relative [&>div]:hover:visible [&>div]:hover:pt-3 [&>div]:hover:h-full [&>div]:hover:opacity-100 [&>div]:hover:top-auto [&>div]:hover:transition-all [&>div]:hover:z-10 pr-9">
                    <Link to={"/success-stories-recovery"} className="link hover:text-blue-500 flex items-center">
                      Success Stories + Recovery
                      <svg className="w-3 h-auto ml-2" width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                      </svg>
                    </Link>
                    <div className="absolute top-9 invisible opacity-0 whitespace-nowrap pt-0">
                      <div className="max-w-sm shadow-front-3 rounded-lg whitespace-nowrap bg-white dark:bg-foreground dark:text-gray-300 text-gray-700">
                        <div className="dropdown-links">
                          <ul className="p-4">
                            <li>
                              <Link to={"/opioid-addiction-stories"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Opioid Addiction Stories</Link>
                            </li>
                            <li>
                              <Link to={"/sobriety-engine"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Sobriety Engine</Link>
                            </li>
                            <li>
                              <Link to={"/recovery-resources"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Recovery Resources (Blog)</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                {/* <li className="list-none">
                  <div className="relative [&>div]:hover:visible [&>div]:hover:pt-3 [&>div]:hover:h-full [&>div]:hover:opacity-100 [&>div]:hover:top-auto [&>div]:hover:transition-all [&>div]:hover:z-10 pr-9">
                    <Link className="link hover:text-blue-500 flex items-center" style={{ cursor: "pointer" }} to={"/about-us"}>
                      About Us
                    </Link>
                  </div>
                </li> */}

                {
                  userLoggedIn
                    ?
                    (
                      <CreateRoleBasedNavigation />
                    )
                    :
                    (
                      <li className="list-none">
                        <div className="relative [&>div]:hover:visible [&>div]:hover:pt-3 [&>div]:hover:h-full [&>div]:hover:opacity-100 [&>div]:hover:top-auto [&>div]:hover:transition-all [&>div]:hover:z-10 pr-9">
                          <Link to={"/success-stories-recovery"} className="link hover:text-blue-500 flex items-center">
                            More
                            <svg className="w-3 h-auto ml-2" width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                            </svg>
                          </Link>
                          <div className="absolute top-9 invisible opacity-0 whitespace-nowrap pt-0">
                            <div className="max-w-sm shadow-front-3 rounded-lg whitespace-nowrap bg-white dark:bg-foreground dark:text-gray-300 text-gray-700" style={{ minWidth: "150px" }}>
                              <div className="dropdown-links">
                                <ul className="p-4">
                                  <li>
                                    <Link to={"/about-us"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">About Us</Link>
                                  </li>
                                  <li>
                                    <Link to={"/login"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Login</Link>
                                  </li>
                                  <li>
                                    <Link to={"/register"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Register</Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    )
                }

                <li className="mr-9">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-300 cursor-pointer darkMode" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-500 cursor-pointer lightMode hidden" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clipRule="evenodd" />
                  </svg>
                </li>
              </ul>
              <button type="button" className="inline-flex hover:bg-blue-500 hover:text-white items-center px-3 py-[9px] rounded-lg border hover:border-blue-100 xl:block hidden" style={{ display: "none" }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 xl:mr-2 mr-0" viewBox="0 0 24 24" width={14} height={14} fill="currentColor">
                  <path fillRule="evenodd" d="M23.98 2.023v4a1 1 0 0 1-2 0V3.46l-5.3 5.272a1 1 0 0 1-1.41-1.418l5.32-5.291h-2.61a1 1 0 0 1 0-2h4a2 2 0 0 1 2 2Zm-.905 14.739a3.1 3.1 0 0 1 0 4.378l-.912 1.05c-8.191 7.838-28.12-12.085-20.4-20.3l1.149-1a3.085 3.085 0 0 1 4.329.038c.03.031 1.883 2.439 1.883 2.439a3.1 3.1 0 0 1-.006 4.281L7.959 9.105a12.781 12.781 0 0 0 6.931 6.945l1.465-1.165a3.1 3.1 0 0 1 4.28-.006s2.409 1.853 2.44 1.883ZM21.7 18.216s-2.393-1.842-2.424-1.872a1.1 1.1 0 0 0-1.549 0c-.027.026-2.044 1.634-2.044 1.634a1 1 0 0 1-.979.152A15 15 0 0 1 5.88 9.318a1 1 0 0 1 .145-.995s1.607-2.017 1.634-2.044a1.1 1.1 0 0 0 0-1.549c-.03-.03-1.872-2.424-1.872-2.424a1.1 1.1 0 0 0-1.51.038l-1.149 1C-2.516 10.126 14.757 26.442 20.7 20.826l.912-1.05a1.12 1.12 0 0 0 .088-1.56Z" />
                </svg>
                <span><Link to={`tel:1234567891`}>123 456 7891</Link></span>
              </button>
              <button type="button" className="inline-flex hover:bg-blue-500 hover:text-white items-center px-3 py-[9px] rounded-lg border hover:border-blue-100 xl:block hidden">
                <Link to={'/contact-us'}>Contact Us</Link>
              </button>
            </div>
          </nav>
        </div>

        {page === "home" ? <DesktopHeroForm /> : (page === "contact-us" ? <ContactUsHeroForm /> : <AboutUsHero />)}
      </header>

      {page === "home" && <MobileHeroForm />}
    </>
  )
}

const AllPagesNav = () => {
  const navigate = useNavigate();

  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    if (getCookie("token") !== undefined && getCookie("token") !== null) {
      //user is loggedin
      setUserLoggedIn(true);
      setUserRole(checkRole(getCookie("token")));
    }

    // Respond to the `storage` event
    function storageEventHandler(event) {
      if (localStorage.getItem("isLoggedIn") !== null) {
        setUserLoggedIn(true);
        setUserRole(checkRole(getCookie("token")));
      }
      else {
        setUserLoggedIn(false);
        setUserRole('');
      }
    }
    // Hook up the event handler
    window.addEventListener("storage", storageEventHandler);
    return () => {
      // Remove the handler when the component unmounts
      window.removeEventListener("storage", storageEventHandler);
    };
  }, [])

  const logout = () => {
    setTimeout(() => {
      localStorage.removeItem("isLoggedIn");
      eraseCookie("token");
      // navigate(`/admin/login`, { replace: true });
      navigate(`/login`, { replace: true });
    }, 500);
  }

  const CreateRoleBasedNavigation = () => {
    if (userRole === "admin") {
      return <>
        <li className="list-none">
          <div className="relative [&>div]:hover:visible [&>div]:hover:pt-3 [&>div]:hover:h-full [&>div]:hover:opacity-100 [&>div]:hover:top-auto [&>div]:hover:transition-all [&>div]:hover:z-10 pr-9">
            <div className="link hover:text-blue-500 flex items-center" style={{ paddingTop: "0px" }}>
              <div className="flex items-center">
                <div className="relative lg:mr-3 mr-3">
                  <img className="w-8 h-8 rounded-full border border-white" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAMAAAANmfvwAAAAMFBMVEX////S19vR1try8vIAAADQ1dnZ3eDf4uXl5+rW2t75+vrt7/D19vfn6uzx8vQYGShNh6tnAAAA5UlEQVQ4jd2SSQLDIAhFhQZn7f1vWzWolHTYdFVW4ecxijG/t1qcta7Ud/+jo2GIRC6+IjwhEPjiQ/tA8lciEACdFart30ETgE3N08uNQVA5GoEid4DmP+XxLUokMcZhF0RM7ARQVIhUTgGdRoQykgDajdiBAE0/XZHASJrNsi/ajRw1G3aMiKfJjMxmuDCWjejajADsGQM8I1xoNzeT7ELcrhhp5l3tVo5ZM21hTaCRtAS17q1k3cpeVFBp5cOWcQyw1ltpHINYVB+BIEmhXbJ8s7GrYpT5y/H+j92+mjma3Y9P9gDGnQaRgDV0MgAAAABJRU5ErkJggg==" data-dropdown-button="true" alt="" />
                  <div className="w-2 h-2 bg-green-500 rounded-full border absolute bottom-0 right-0"></div>
                </div>
                My Account
              </div>
              <svg className="w-3 h-auto ml-2" width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
              </svg>
            </div>
            <div className="absolute top-9 invisible opacity-0 whitespace-nowrap pt-0">
              <div className="max-w-sm shadow-front-3 rounded-lg whitespace-nowrap bg-white dark:bg-foreground dark:text-gray-300 text-gray-700" style={{ minWidth: "150px" }}>
                <div className="dropdown-links">
                  <ul className="p-4">
                    <li>
                      <Link to={"/admin/dashboard"} className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                        <svg className="w-4 h-4 mr-2" width={20} height={20} fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fill="currentColor" d="M7.143 2c.946 0 1.714.746 1.714 1.667v5.555c0 .92-.768 1.667-1.714 1.667H3.714C2.768 10.889 2 10.142 2 9.222V3.667C2 2.747 2.768 2 3.714 2h3.429Zm0 11.111c.946 0 1.714.747 1.714 1.667v1.11c0 .921-.768 1.668-1.714 1.668H3.714c-.946 0-1.714-.747-1.714-1.667v-1.111c0-.92.768-1.667 1.714-1.667h3.429Z" />
                          <path fill="#B2DDFF" d="M16.286 2C17.232 2 18 2.746 18 3.667v1.11c0 .92-.768 1.667-1.714 1.667h-3.429c-.946 0-1.714-.746-1.714-1.666V3.667c0-.92.768-1.667 1.714-1.667h3.429Zm0 6.667c.946 0 1.714.746 1.714 1.666v5.556c0 .92-.768 1.667-1.714 1.667h-3.429c-.946 0-1.714-.747-1.714-1.667v-5.556c0-.92.768-1.666 1.714-1.666h3.429Z" />
                        </svg>
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link to={"/admin/profile"} className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                        <svg className="w-4 h-4 mr-2" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.8296 11.6H8.57036C5.49461 11.6 3 13.988 3 16.9331C3 17.5221 3.49886 18 4.11407 18H16.2866C16.9018 18.0009 17.4 17.5239 17.4 16.9331C17.4 13.988 14.9057 11.6 11.8296 11.6Z" fill="currentColor" />
                          <path d="M14.2 6C14.2 8.20906 12.4094 10 10.2 10C7.99064 10 6.20001 8.20937 6.20001 6C6.20001 3.79063 7.99095 2 10.2 2C12.4094 2 14.2 3.79094 14.2 6Z" fill="#D0D5DD" />
                        </svg>
                        My Profile
                      </Link>
                    </li>
                    <li>
                      <Link to={"/admin/change_password"} className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                        <svg className="mr-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                        </svg>
                        Change Password
                      </Link>
                    </li>
                    <li>
                      <div className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3" style={{ cursor: "pointer" }} onClick={() => logout()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                        Sign Out
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>
      </>
    }
    else if (userRole === "member") {
      return <>
        <li className="list-none">
          <div className="relative [&>div]:hover:visible [&>div]:hover:pt-3 [&>div]:hover:h-full [&>div]:hover:opacity-100 [&>div]:hover:top-auto [&>div]:hover:transition-all [&>div]:hover:z-10 pr-9">
            <div className="link hover:text-blue-500 flex items-center" style={{ paddingTop: "0px" }}>
              <div className="flex items-center">
                <div className="relative lg:mr-3 mr-3">
                  <img className="w-8 h-8 rounded-full border border-white" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAMAAAANmfvwAAAAMFBMVEX////S19vR1try8vIAAADQ1dnZ3eDf4uXl5+rW2t75+vrt7/D19vfn6uzx8vQYGShNh6tnAAAA5UlEQVQ4jd2SSQLDIAhFhQZn7f1vWzWolHTYdFVW4ecxijG/t1qcta7Ud/+jo2GIRC6+IjwhEPjiQ/tA8lciEACdFart30ETgE3N08uNQVA5GoEid4DmP+XxLUokMcZhF0RM7ARQVIhUTgGdRoQykgDajdiBAE0/XZHASJrNsi/ajRw1G3aMiKfJjMxmuDCWjejajADsGQM8I1xoNzeT7ELcrhhp5l3tVo5ZM21hTaCRtAS17q1k3cpeVFBp5cOWcQyw1ltpHINYVB+BIEmhXbJ8s7GrYpT5y/H+j92+mjma3Y9P9gDGnQaRgDV0MgAAAABJRU5ErkJggg==" data-dropdown-button="true" alt="" />
                  <div className="w-2 h-2 bg-green-500 rounded-full border absolute bottom-0 right-0"></div>
                </div>
                My Account
              </div>
              <svg className="w-3 h-auto ml-2" width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
              </svg>
            </div>
            <div className="absolute top-9 invisible opacity-0 whitespace-nowrap pt-0">
              <div className="max-w-sm shadow-front-3 rounded-lg whitespace-nowrap bg-white dark:bg-foreground dark:text-gray-300 text-gray-700" style={{ minWidth: "150px" }}>
                <div className="dropdown-links">
                  <ul className="p-4">
                    <li>
                      <Link to={"/admin/add_listing"} className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                        <svg className="w-4 h-4 mr-2" width={20} height={20} fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fill="currentColor" d="M7.143 2c.946 0 1.714.746 1.714 1.667v5.555c0 .92-.768 1.667-1.714 1.667H3.714C2.768 10.889 2 10.142 2 9.222V3.667C2 2.747 2.768 2 3.714 2h3.429Zm0 11.111c.946 0 1.714.747 1.714 1.667v1.11c0 .921-.768 1.668-1.714 1.668H3.714c-.946 0-1.714-.747-1.714-1.667v-1.111c0-.92.768-1.667 1.714-1.667h3.429Z" />
                          <path fill="#B2DDFF" d="M16.286 2C17.232 2 18 2.746 18 3.667v1.11c0 .92-.768 1.667-1.714 1.667h-3.429c-.946 0-1.714-.746-1.714-1.666V3.667c0-.92.768-1.667 1.714-1.667h3.429Zm0 6.667c.946 0 1.714.746 1.714 1.666v5.556c0 .92-.768 1.667-1.714 1.667h-3.429c-.946 0-1.714-.747-1.714-1.667v-5.556c0-.92.768-1.666 1.714-1.666h3.429Z" />
                        </svg>
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link to={"/admin/profile"} className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                        <svg className="w-4 h-4 mr-2" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.8296 11.6H8.57036C5.49461 11.6 3 13.988 3 16.9331C3 17.5221 3.49886 18 4.11407 18H16.2866C16.9018 18.0009 17.4 17.5239 17.4 16.9331C17.4 13.988 14.9057 11.6 11.8296 11.6Z" fill="currentColor" />
                          <path d="M14.2 6C14.2 8.20906 12.4094 10 10.2 10C7.99064 10 6.20001 8.20937 6.20001 6C6.20001 3.79063 7.99095 2 10.2 2C12.4094 2 14.2 3.79094 14.2 6Z" fill="#D0D5DD" />
                        </svg>
                        My Profile
                      </Link>
                    </li>
                    <li>
                      <Link to={"/admin/change_password"} className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                        <svg className="mr-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                        </svg>
                        Change Password
                      </Link>
                    </li>
                    <li>
                      <div className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3" style={{ cursor: "pointer" }} onClick={() => logout()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                        Sign Out
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>
      </>
    }
    else if (userRole === "author") {
      return <>
        <li className="list-none">
          <div className="relative [&>div]:hover:visible [&>div]:hover:pt-3 [&>div]:hover:h-full [&>div]:hover:opacity-100 [&>div]:hover:top-auto [&>div]:hover:transition-all [&>div]:hover:z-10 pr-9">
            <div className="link hover:text-blue-500 flex items-center" style={{ paddingTop: "0px" }}>
              <div className="flex items-center">
                <div className="relative lg:mr-3 mr-3">
                  <img className="w-8 h-8 rounded-full border border-white" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAMAAAANmfvwAAAAMFBMVEX////S19vR1try8vIAAADQ1dnZ3eDf4uXl5+rW2t75+vrt7/D19vfn6uzx8vQYGShNh6tnAAAA5UlEQVQ4jd2SSQLDIAhFhQZn7f1vWzWolHTYdFVW4ecxijG/t1qcta7Ud/+jo2GIRC6+IjwhEPjiQ/tA8lciEACdFart30ETgE3N08uNQVA5GoEid4DmP+XxLUokMcZhF0RM7ARQVIhUTgGdRoQykgDajdiBAE0/XZHASJrNsi/ajRw1G3aMiKfJjMxmuDCWjejajADsGQM8I1xoNzeT7ELcrhhp5l3tVo5ZM21hTaCRtAS17q1k3cpeVFBp5cOWcQyw1ltpHINYVB+BIEmhXbJ8s7GrYpT5y/H+j92+mjma3Y9P9gDGnQaRgDV0MgAAAABJRU5ErkJggg==" data-dropdown-button="true" alt="" />
                  <div className="w-2 h-2 bg-green-500 rounded-full border absolute bottom-0 right-0"></div>
                </div>
                My Account
              </div>
              <svg className="w-3 h-auto ml-2" width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
              </svg>
            </div>
            <div className="absolute top-9 invisible opacity-0 whitespace-nowrap pt-0">
              <div className="max-w-sm shadow-front-3 rounded-lg whitespace-nowrap bg-white dark:bg-foreground dark:text-gray-300 text-gray-700" style={{ minWidth: "150px" }}>
                <div className="dropdown-links">
                  <ul className="p-4">
                    <li>
                      <Link to={"/admin/add_post"} className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                        <svg className="w-4 h-4 mr-2" width={20} height={20} fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fill="currentColor" d="M7.143 2c.946 0 1.714.746 1.714 1.667v5.555c0 .92-.768 1.667-1.714 1.667H3.714C2.768 10.889 2 10.142 2 9.222V3.667C2 2.747 2.768 2 3.714 2h3.429Zm0 11.111c.946 0 1.714.747 1.714 1.667v1.11c0 .921-.768 1.668-1.714 1.668H3.714c-.946 0-1.714-.747-1.714-1.667v-1.111c0-.92.768-1.667 1.714-1.667h3.429Z" />
                          <path fill="#B2DDFF" d="M16.286 2C17.232 2 18 2.746 18 3.667v1.11c0 .92-.768 1.667-1.714 1.667h-3.429c-.946 0-1.714-.746-1.714-1.666V3.667c0-.92.768-1.667 1.714-1.667h3.429Zm0 6.667c.946 0 1.714.746 1.714 1.666v5.556c0 .92-.768 1.667-1.714 1.667h-3.429c-.946 0-1.714-.747-1.714-1.667v-5.556c0-.92.768-1.666 1.714-1.666h3.429Z" />
                        </svg>
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link to={"/admin/profile"} className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                        <svg className="w-4 h-4 mr-2" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.8296 11.6H8.57036C5.49461 11.6 3 13.988 3 16.9331C3 17.5221 3.49886 18 4.11407 18H16.2866C16.9018 18.0009 17.4 17.5239 17.4 16.9331C17.4 13.988 14.9057 11.6 11.8296 11.6Z" fill="currentColor" />
                          <path d="M14.2 6C14.2 8.20906 12.4094 10 10.2 10C7.99064 10 6.20001 8.20937 6.20001 6C6.20001 3.79063 7.99095 2 10.2 2C12.4094 2 14.2 3.79094 14.2 6Z" fill="#D0D5DD" />
                        </svg>
                        My Profile
                      </Link>
                    </li>
                    <li>
                      <Link to={"/admin/change_password"} className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                        <svg className="mr-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                        </svg>
                        Change Password
                      </Link>
                    </li>
                    <li>
                      <div className="flex items-center hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3" style={{ cursor: "pointer" }} onClick={() => logout()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                        Sign Out
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>
      </>
    }
  }

  return (
    <header className="w-full navbar h-20 flex items-center sticky-header dark:bg-background dark:text-gray-100 transition-all left-0">
      <nav className="container mx-auto flex items-center justify-between lg:px-5 px-5">
        <button type="button" className="lg:hidden block menu-btn" aria-label="Mobile Menu Button">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
        <Link to={"/"} className="flex items-center">
          <img className="w-13 h-13 mr-3" src="/img/OR-logo.webp" alt="" width={50} height={50} />
        </Link>
        <div className="hidden lg:flex items-center">
          <ul className="flex items-center">

            <li className="list-none">
              <div className="relative [&>div]:hover:visible [&>div]:hover:pt-3 [&>div]:hover:h-full [&>div]:hover:opacity-100 [&>div]:hover:top-auto [&>div]:hover:transition-all [&>div]:hover:z-10 pr-9">
                <Link to={"/opioid-addiction"} className="link hover:text-blue-500 flex items-center">
                  Opioid Addiction
                  <svg className="w-3 h-auto ml-2" width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                  </svg>
                </Link>
                <div className="absolute top-9 invisible opacity-0 whitespace-nowrap pt-0">
                  <div className="max-w-sm shadow-front-3 rounded-lg whitespace-nowrap bg-white dark:bg-foreground dark:text-gray-300 text-gray-700">
                    <div className="dropdown-links">
                      <ul className="p-4">
                        <li>
                          <Link to={"/signs-of-opioid-addiction"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Signs of Opioid Addiction</Link>
                        </li>
                        <li>
                          <Link to={"/effects-of-opioid-dependency"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Effects of Opioid Dependency</Link>
                        </li>
                        <li>
                          <Link to={"/signs-of-opioid-overdose"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Signs of Opioid Overdose</Link>
                        </li>
                        <li>
                          <Link to={"/opioid-addiction-risk-factors"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Opioid Addiction Risk Factors</Link>
                        </li>
                        <li>
                          <Link to={"/am-i-addicted-to-opioids"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Am I Addicted to Opioids?</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li className="list-none">
              <div className="relative [&>div]:hover:visible [&>div]:hover:pt-3 [&>div]:hover:h-full [&>div]:hover:opacity-100 [&>div]:hover:top-auto [&>div]:hover:transition-all [&>div]:hover:z-10 pr-9">
                <Link to={"/find-opioid-treatment"} className="link hover:text-blue-500 flex items-center">
                  Find Opioid Treatment
                  <svg className="w-3 h-auto ml-2" width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                  </svg>
                </Link>
                <div className="absolute top-9 invisible opacity-0 whitespace-nowrap pt-0">
                  <div className="max-w-sm shadow-front-3 rounded-lg whitespace-nowrap bg-white dark:bg-foreground dark:text-gray-300 text-gray-700">
                    <div className="dropdown-links">
                      <ul className="p-4">
                        <li>
                          <Link to={"/detox"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Detox</Link>
                        </li>
                        <li>
                          <Link to={"/residential-treatment"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Residential Treatment</Link>
                        </li>
                        <li>
                          <Link to={"/medication-assisted-treatment"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Medication-Assisted Treatment</Link>
                        </li>
                        <li>
                          <Link to={"/virtual-treatment"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Virtual Treatment</Link>
                        </li>
                        <li>
                          <Link to={"/outpatient-treatment"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Outpatient Treatment</Link>
                        </li>
                        <li>
                          <Link to={"/paying-for-treatment"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Paying For Treatment</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li className="list-none">
              <div className="relative [&>div]:hover:visible [&>div]:hover:pt-3 [&>div]:hover:h-full [&>div]:hover:opacity-100 [&>div]:hover:top-auto [&>div]:hover:transition-all [&>div]:hover:z-10 pr-9">
                <Link to={"/success-stories-recovery"} className="link hover:text-blue-500 flex items-center">
                  Success Stories + Recovery
                  <svg className="w-3 h-auto ml-2" width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                  </svg>
                </Link>
                <div className="absolute top-9 invisible opacity-0 whitespace-nowrap pt-0">
                  <div className="max-w-sm shadow-front-3 rounded-lg whitespace-nowrap bg-white dark:bg-foreground dark:text-gray-300 text-gray-700">
                    <div className="dropdown-links">
                      <ul className="p-4">
                        <li>
                          <Link to={"/opioid-addiction-stories"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Opioid Addiction Stories</Link>
                        </li>
                        <li>
                          <Link to={"/sobriety-engine"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Sobriety Engine</Link>
                        </li>
                        <li>
                          <Link to={"/recovery-resources"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Recovery Resources (Blog)</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            {/* <li className="list-none">
              <div className="relative [&>div]:hover:visible [&>div]:hover:pt-3 [&>div]:hover:h-full [&>div]:hover:opacity-100 [&>div]:hover:top-auto [&>div]:hover:transition-all [&>div]:hover:z-10 pr-9">
                <Link className="link hover:text-blue-500 flex items-center" style={{ cursor: "pointer" }} to={"/about-us"}>
                  About Us
                </Link>
              </div>
            </li> */}
            {
              userLoggedIn
                ?
                (
                  <CreateRoleBasedNavigation />
                )
                :
                (
                  <li className="list-none">
                    <div className="relative [&>div]:hover:visible [&>div]:hover:pt-3 [&>div]:hover:h-full [&>div]:hover:opacity-100 [&>div]:hover:top-auto [&>div]:hover:transition-all [&>div]:hover:z-10 pr-9">
                      <Link to={"/success-stories-recovery"} className="link hover:text-blue-500 flex items-center">
                        More
                        <svg className="w-3 h-auto ml-2" width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                        </svg>
                      </Link>
                      <div className="absolute top-9 invisible opacity-0 whitespace-nowrap pt-0">
                        <div className="max-w-sm shadow-front-3 rounded-lg whitespace-nowrap bg-white dark:bg-foreground dark:text-gray-300 text-gray-700" style={{ minWidth: "150px" }}>
                          <div className="dropdown-links">
                            <ul className="p-4">
                              <li>
                                <Link to={"/about-us"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">About Us</Link>
                              </li>
                              <li>
                                <Link to={"/login"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Login</Link>
                              </li>
                              <li>
                                <Link to={"/register"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Register</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )
            }

            <li className="mr-9">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-300 cursor-pointer darkMode" viewBox="0 0 20 20" fill="currentColor">
                <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-500 cursor-pointer lightMode hidden" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clipRule="evenodd" />
              </svg>
            </li>
          </ul>
          <button type="button" className="inline-flex hover:bg-blue-500 hover:text-white items-center px-3 py-[9px] rounded-lg border hover:border-blue-100" style={{ display: "none" }}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 xl:mr-2 mr-0" viewBox="0 0 24 24" width={14} height={14} fill="currentColor">
              <path fillRule="evenodd" d="M23.98 2.023v4a1 1 0 0 1-2 0V3.46l-5.3 5.272a1 1 0 0 1-1.41-1.418l5.32-5.291h-2.61a1 1 0 0 1 0-2h4a2 2 0 0 1 2 2Zm-.905 14.739a3.1 3.1 0 0 1 0 4.378l-.912 1.05c-8.191 7.838-28.12-12.085-20.4-20.3l1.149-1a3.085 3.085 0 0 1 4.329.038c.03.031 1.883 2.439 1.883 2.439a3.1 3.1 0 0 1-.006 4.281L7.959 9.105a12.781 12.781 0 0 0 6.931 6.945l1.465-1.165a3.1 3.1 0 0 1 4.28-.006s2.409 1.853 2.44 1.883ZM21.7 18.216s-2.393-1.842-2.424-1.872a1.1 1.1 0 0 0-1.549 0c-.027.026-2.044 1.634-2.044 1.634a1 1 0 0 1-.979.152A15 15 0 0 1 5.88 9.318a1 1 0 0 1 .145-.995s1.607-2.017 1.634-2.044a1.1 1.1 0 0 0 0-1.549c-.03-.03-1.872-2.424-1.872-2.424a1.1 1.1 0 0 0-1.51.038l-1.149 1C-2.516 10.126 14.757 26.442 20.7 20.826l.912-1.05a1.12 1.12 0 0 0 .088-1.56Z" />
            </svg>
            <span className="xl:block hidden"><Link to={`tel:1234567891`}>123 456 7891</Link></span>
          </button>
          <button type="button" className="inline-flex hover:bg-blue-500 hover:text-white items-center px-3 py-[9px] rounded-lg border hover:border-blue-100 xl:block hidden">
            <Link to={'/contact-us'}>Contact Us</Link>
          </button>
        </div>
      </nav>
    </header>
  )
}

const DesktopNav = (props) => {
  //return (props.type === "home" ? HomeNav() : AllPagesNav())
  if (props.type === "home" || props.type === "contact-us" || props.type === "about-us")
    return HomeNav(props.type);
  else
    return AllPagesNav();
}

export default DesktopNav