import React, { useState, useEffect } from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

import ScrollToTopButton from '../../components/ScrollToTopButton';
import { appendScript, removeScript } from "../../func";

//import businesses_list from "../../../business";
import FeaturedListing from '../../components/FeaturedListing';
import RehabSearchForm from '../../components/RehabSearchForm';
import MedicalReview from '../../components/MedicalReview';
import VisitOurFacility from '../../components/VisitOurFacility';
import TrendingListing from "../../components/TrendingListing";

const Questions = [{
  type: "myself",
  questions: ["Do you often use drugs or alcohol in larger amounts or over a longer period of time than you intended?", "Have you for a while now wanted to cut back on drugs or alcohol or made unsuccessful attempts to do so?", "Do you spend a great deal of time finding, using, or recovering from drugs or alcohol?", "Do you have strong urges or powerful cravings to use drugs or alcohol?", "Has your use of drugs or alcohol resulted in your inability to meet your obligations at work, home, or school?", "Have you had to cut back on or abandon social, professional, or recreational activities due to your use of drugs or alcohol?", "Have you repeatedly used drugs or alcohol when it was hazardous to do so, such as while driving a car or operating machinery?", "Have you experienced social or relationship problems due to your use of drugs or alcohol and kept using anyway?", "Have you kept using drugs or alcohol knowing that it has caused or worsened physical or mental health issues?", "When you attempt to cut back on or stop your use of drugs or alcohol, have you experienced uncomfortable physical or mental health symptoms (withdrawal)?", "Have you experienced diminished effects when you use drugs or alcohol compared to the past and/or have you needed more drugs or alcohol in order to feel the effects you're seeking (tolerance)?"]
},
{
  type: "lovedone",
  questions: ["Does your loved one often use drugs or alcohol in larger amounts or over a longer period of time than they intended?", "Has your loved one expressed a desire to cut back on drugs or alcohol or made unsuccessful attempts to do so?", "Does your loved one spend a great deal of time finding, using, or recovering from drugs or alcohol?", "Does your loved one crave or have strong urges to use drugs or alcohol?", "Has your loved one's use of drugs or alcohol negatively impacted their ability to meet their obligations at work, home, or school?", "Has your loved one cut back on or abandoned social, professional, or recreational activities due to their use of drugs or alcohol?", "Has your loved one ever driven while intoxicated or used drugs or alcohol in other hazardous situations, such as before operating machinery?", "Has your loved one experienced social or relationship problems due to their use of drugs or alcohol and kept using anyway?", "Has your loved one kept using drugs or alcohol knowing that it has caused or worsened physical or mental health issues?", "When your loved one attempts to cut back on or stop their use of drugs or alcohol, do they experience uncomfortable physical or mental health symptoms (withdrawal)?", "Has your loved one experienced diminished effects when they use drugs or alcohol compared to the past and/or have they needed more drugs or alcohol in order to feel the effects they're seeking (tolerance)?"]
}]

const ResultResponses = [{
  type: "myself",
  response: "yes",
  message: "Your results indicate that you may meet the diagnostic criteria for a substance use disorder, though only a doctor or addiction treatment professional may provide such a diagnosis. If you feel your use of drugs or alcohol is impacting your life negatively and want help, we're here. Treatment for addiction has helped millions of people who struggle with substance use get their lives back on track.||1. American Psychiatric Association. (2013). Diagnostic and statistical manual of mental disorders (5th ed.). Arlington, VA: Author."
},
{
  type: "myself",
  response: "no",
  message: "Your results indicate that you may not meet the diagnostic criteria for a substance use disorder. However, if you feel your use of drugs or alcohol is impacting your life negatively and want help, we're here. Treatment for addiction has helped millions of people who struggle with substance use get their lives back on track.||1. American Psychiatric Association. (2013). Diagnostic and statistical manual of mental disorders (5th ed.). Arlington, VA: Author."
},
{
  type: "lovedone",
  response: "yes",
  message: "Your results indicate that your loved one may meet the diagnostic criteria for a substance use disorder, though only a doctor or addiction treatment professional may provide such a diagnosis. If you feel their use of drugs or alcohol is impacting their life negatively, we can help, we're here. Treatment for addiction has helped millions of people who struggle with substance use get their lives back on track.||1. American Psychiatric Association. (2013). Diagnostic and statistical manual of mental disorders (5th ed.). Arlington, VA: Author."
},
{
  type: "lovedone",
  response: "no",
  message: "Your results indicate that your loved one may not meet the diagnostic criteria for a substance use disorder. However, if you feel their use of drugs or alcohol is impacting their life negatively and want help, we're here. Treatment for addiction has helped millions of people who struggle with substance use get their lives back on track.||1. American Psychiatric Association. (2013). Diagnostic and statistical manual of mental disorders (5th ed.). Arlington, VA: Author."
}]

//let Answers = [];

const AmIAddictedToOpioids = () => {

  const [forperson, setForPerson] = useState('');
  const [beginAssessment, setBeginAssessment] = useState(false);

  //const [evaluate, setEvaluate] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [evaluate, setEvaluate] = useState(false);

  useEffect(() => {
    appendScript("/js/plugins-core/handy-collapse.js", "handy-collapse");
    setTimeout(() => {
      appendScript("/js/plugins-script/handy-collapse.js", "handy-collapse-function");
    }, 100)

    return () => {
      removeScript("handy-collapse");
      removeScript("handy-collapse-function");
    }

  }, []);

  // useEffect(() => {
  //   console.log("beginAssessment: ", beginAssessment);
  // }, [beginAssessment])

  // const featuredListing = () => {
  //   return (
  //     <div className="shadow-front-2 dark:bg-foreground p-6 rounded-lg mb-7">
  //       <div className="flex justify-between mb-6">
  //         <h4 className="text-base font-medium text-gray-700 dark:text-gray-100">
  //           Feature Listings
  //         </h4>
  //         {/* <a href="#" className="text-gray-500 dark:text-gray-400 text-sm">More</a> */}
  //       </div>

  //       {
  //         businesses_list.map(({ alias, name, image_url, is_closed }, index) => (
  //           index < 5 && <Link to={`/details/${alias}`} key={index} className="flex mb-6">
  //             <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src={image_url} alt={name} />
  //             <div>
  //               <h6 className="text-gray-700 dark:text-gray-300 font-medium mb-2">
  //                 {name}
  //               </h6>
  //               <div className="dark:bg-gray-900 rounded-full justify-center inline-flex items-center">
  //                 {
  //                   !is_closed ?
  //                     (<span className="bg-green-600 text-xs font-medium text-white px-3 py-1 rounded-full">Open</span>)
  //                     :
  //                     (<span className="bg-red-100 text-xs font-medium dark:text-gray-400 px-3 py-1 rounded-full">Close</span>)
  //                 }
  //               </div>
  //               {/* <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
  //                 <svg className="w-3 h-auto text-green-500 mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  //                   <path d="M4.6498 2.39995H7.3498V1.64995C7.3498 1.40151 7.55043 1.19995 7.7998 1.19995C8.04918 1.19995 8.2498 1.40151 8.2498 1.64995V2.39995H8.9998C9.66168 2.39995 10.1998 2.93714 10.1998 3.59995V9.59995C10.1998 10.2618 9.66168 10.8 8.9998 10.8H2.9998C2.33699 10.8 1.7998 10.2618 1.7998 9.59995V3.59995C1.7998 2.93714 2.33699 2.39995 2.9998 2.39995H3.7498V1.64995C3.7498 1.40151 3.95043 1.19995 4.1998 1.19995C4.44918 1.19995 4.6498 1.40151 4.6498 1.64995V2.39995ZM2.6998 9.59995C2.6998 9.76495 2.83405 9.89995 2.9998 9.89995H8.9998C9.1648 9.89995 9.2998 9.76495 9.2998 9.59995V4.79995H2.6998V9.59995Z" fill="currentColor"></path>
  //                 </svg>
  //                 <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
  //               </div> */}
  //             </div>
  //           </Link>
  //         ))
  //       }

  //       {/* <a href="#" className="flex mb-6">
  //         <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src="img/Image/landing-page-image/building-2.jpg" alt="" />
  //         <div>
  //           <h6 className="text-gray-700 dark:text-gray-300 font-medium mb-2">
  //             Duplex Apartment with Pool
  //           </h6>
  //           <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
  //             <svg className="w-3 h-auto text-green-500 mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  //               <path d="M4.6498 2.39995H7.3498V1.64995C7.3498 1.40151 7.55043 1.19995 7.7998 1.19995C8.04918 1.19995 8.2498 1.40151 8.2498 1.64995V2.39995H8.9998C9.66168 2.39995 10.1998 2.93714 10.1998 3.59995V9.59995C10.1998 10.2618 9.66168 10.8 8.9998 10.8H2.9998C2.33699 10.8 1.7998 10.2618 1.7998 9.59995V3.59995C1.7998 2.93714 2.33699 2.39995 2.9998 2.39995H3.7498V1.64995C3.7498 1.40151 3.95043 1.19995 4.1998 1.19995C4.44918 1.19995 4.6498 1.40151 4.6498 1.64995V2.39995ZM2.6998 9.59995C2.6998 9.76495 2.83405 9.89995 2.9998 9.89995H8.9998C9.1648 9.89995 9.2998 9.76495 9.2998 9.59995V4.79995H2.6998V9.59995Z" fill="currentColor"></path>
  //             </svg>

  //             <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
  //           </div>
  //         </div>
  //       </a>
  //       <a href="#" className="flex mb-6">
  //         <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src="img/Image/event-landing-page/concert.jpg" alt="" />
  //         <div>
  //           <h6 className="text-gray-700 dark:text-gray-300 font-medium mb-2">
  //             Tomorrowland Concert
  //           </h6>
  //           <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
  //             <svg className="w-3 h-auto text-green-500 mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  //               <path d="M4.6498 2.39995H7.3498V1.64995C7.3498 1.40151 7.55043 1.19995 7.7998 1.19995C8.04918 1.19995 8.2498 1.40151 8.2498 1.64995V2.39995H8.9998C9.66168 2.39995 10.1998 2.93714 10.1998 3.59995V9.59995C10.1998 10.2618 9.66168 10.8 8.9998 10.8H2.9998C2.33699 10.8 1.7998 10.2618 1.7998 9.59995V3.59995C1.7998 2.93714 2.33699 2.39995 2.9998 2.39995H3.7498V1.64995C3.7498 1.40151 3.95043 1.19995 4.1998 1.19995C4.44918 1.19995 4.6498 1.40151 4.6498 1.64995V2.39995ZM2.6998 9.59995C2.6998 9.76495 2.83405 9.89995 2.9998 9.89995H8.9998C9.1648 9.89995 9.2998 9.76495 9.2998 9.59995V4.79995H2.6998V9.59995Z" fill="currentColor"></path>
  //             </svg>

  //             <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
  //           </div>
  //         </div>
  //       </a>
  //       <a href="#" className="flex">
  //         <img className="w-24 h-auto flex-shrink-0 rounded-lg mr-4" src="img/Image/landing-page-image/palace.jpg" alt="" />
  //         <div>
  //           <h6 className="text-gray-700 dark:text-gray-300 font-medium mb-2">
  //             Luxary Hotel For Rent
  //           </h6>
  //           <div className="bg-gray-100 dark:bg-gray-900 rounded-full px-3 py-1 inline-flex items-center">
  //             <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-auto text-yellow-500 mr-1" viewBox="0 0 20 20" fill="currentColor">
  //               <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
  //             </svg>

  //             <span className="text-xs font-medium dark:text-gray-400">Instant Booking</span>
  //           </div>
  //         </div>
  //       </a> */}
  //     </div>
  //   )
  // }

  const handleAnswer = (question, answer) => {
    let temp = [...answers];
    const i = temp.findIndex(e => e.question === question);
    if (i > -1)
      temp[i].answer = answer;
    else
      temp.push({ "question": question, "answer": answer });

    setAnswers(temp);
    //console.log("Answers: ", Answers);
  }

  const selfAssessment = () => {
    return (
      <div className="rounded-lg shadow-fornt-2 dark:bg-foreground bg-white mb-7 border border-gray-200 dark:border-gray-700">
        {/* <div className="p-6 border-b border-gray-200 text-gray-700 dark:border-gray-800 dark:text-gray-100">
                        <div className="text-center">Self-Assessment: Am I Addicted?</div>
                      </div> */}
        <div className="bg-blue-500 text-white font-medium mr-2 lg:text-3xl text-2xl p-3.5" style={{ marginRight: "0px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
          <div className="text-center">Self-Evaluation: Do I Have an Addiction?</div>
        </div>
        <div className="p-6">
          <p className="text-base text-gray-800 dark:text-gray-200 mb-2">
            Do you have concerns for a loved one or yourself?
          </p>

          <div className='flex mb-2'>
            <div className="flex items-center" onClick={() => setForPerson("myself")}>
              <input id="rbMySelf" type="radio" value="myself" name="person" className="w-8 h-8 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
              <label htmlFor="rbMySelf" className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Myself</label>
            </div>
            <div className="flex items-center pl-4" onClick={() => setForPerson("lovedone")}>
              <input id="rbLovedOne" type="radio" value="lovedone" name="person" className="w-8 h-8 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
              <label htmlFor="rbLovedOne" className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Loved one</label>
            </div>
          </div>
          {
            (forperson !== "" && forperson === "myself") && (<p className="text-gray-500 text-base dark:text-gray-300 mb-5">If you've noticed that your use of drugs or alcohol has grown out of control or is negatively affecting your life, relationships, or health, you may be questioning whether you have a substance use disorder. If you have been using drugs or alcohol and wonder if you may be addicted, answer the following 11 questions honestly.</p>)
          }
          {
            (forperson !== "" && forperson === "lovedone") && (<p className="text-gray-500 text-base dark:text-gray-300 mb-5">If you've noticed that your loved one's use of drugs or alcohol is negatively affecting their life, relationships, or health, you may be questioning whether they have a substance use disorder. If you're worried and wondering whether they are addicted to drugs or alcohol, answer the following 11 questions as honestly as possible.</p>)
          }
          {/* {
            forperson !== ""
              ?
              (forperson === "myself" && (<p className="text-gray-500 text-base dark:text-gray-300 mb-5">If you've noticed that your use of drugs or alcohol has grown out of control or is negatively affecting your life, relationships, or health, you may be questioning whether you have a substance use disorder. If you have been using drugs or alcohol and wonder if you may be addicted, answer the following 11 questions honestly.</p>))
              :
              (forperson === "lovedone" && (<p className="text-gray-500 text-base dark:text-gray-300 mb-5">If you've noticed that your loved one's use of drugs or alcohol is negatively affecting their life, relationships, or health, you may be questioning whether they have a substance use disorder. If you're worried and wondering whether they are addicted to drugs or alcohol, answer the following 11 questions as honestly as possible.</p>))
          } */}

          {forperson.trim() === "" ?
            (<button type="button" className="border border-gray-200 dark:border-gray-700 flex justify-center items-center rounded-lg px-10 py-[9px] text-gray-500 dark:text-gray-400 lg:mr-7 mr-0 lg:mb-0 mb-4" disabled={true}>
              {/* <svg className="w-4 h-4 mr-3" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.8901 1.52131L11.2901 11.899C11.2526 12.1423 11.1057 12.3545 10.8909 12.4752C10.7698 12.5432 10.6341 12.5776 10.4989 12.5776C10.3942 12.5776 10.2909 12.5573 10.1916 12.5158L7.12656 11.2383L5.85504 13.1456C5.75754 13.3125 5.59504 13.4 5.42004 13.4C5.13253 13.4 4.90003 13.1675 4.90003 12.88V10.4754C4.90003 10.2976 4.95933 10.1247 5.06858 9.98443L10.5001 2.99983L3.15751 9.60742L0.592224 8.53741C0.31092 8.41991 0.12028 8.1549 0.10153 7.8274C0.0827795 7.49989 0.238257 7.23439 0.503035 7.08314L11.7032 0.705553C11.9704 0.552826 12.3002 0.566876 12.5532 0.740703C12.8062 0.914531 12.9376 1.21781 12.8901 1.52131Z" fill="currentColor"></path>
            </svg> */}
              Start the Evaluation
            </button>)
            :
            (<button type="button" className="bg-blue-500 text-white hover:bg-blue-600 rounded-lg px-4 py-2 mb-4" onClick={() => setBeginAssessment(true)}>Begin Assessment</button>)
          }

          <p className="text-xs text-gray-500 dark:text-gray-400 mb-2 mr-4 pt-5">
            <span className="text-gray-800 dark:text-gray-200 mr-1">Disclaimer:</span>The diagnosis of a drug use disorder may only be made by a medical or clinical practitioner. While this evaluation could point to a possible addiction, it shouldn't take the place of a professional treatment provider's diagnosis.
          </p>
        </div>
      </div>
    )
  }

  const startAssessment = () => {
    return (
      <div className="rounded-lg shadow-fornt-2 dark:bg-foreground bg-white mb-7 border border-gray-200 dark:border-gray-700">
        {/* <div className="p-6 border-b border-gray-200 text-gray-700 dark:border-gray-800 dark:text-gray-100">
                        <div className="text-center">Self-Assessment: Am I Addicted?</div>
                      </div> */}
        <div className="bg-blue-500 text-white font-medium mr-2 lg:text-3xl text-2xl p-3.5" style={{ marginRight: "0px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
          <div className="text-center">Self-Assessment: Am I Addicted?</div>
        </div>
        <div className="p-6">

          {Questions.find(q => q.type === forperson).questions.map((question, index) => {
            return (
              <div className='p-2' key={index}>
                <p className="text-base text-gray-800 dark:text-gray-200">
                  {index + 1}. {question}
                </p>
                <div className='flex'>
                  <div className="flex items-center" onClick={() => handleAnswer(question, "Yes")}>
                    <input id={`rb${index}Yes`} type="radio" value="Yes" name={`rb${index}`} className="w-8 h-8 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor={`rb${index}Yes`} className="py-2 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                  </div>
                  <div className="flex items-center pl-4" onClick={() => handleAnswer(question, "No")}>
                    <input id={`rb${index}No`} type="radio" value="No" name={`rb${index}`} className="w-8 h-8 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor={`rb${index}No`} className="py-2 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                  </div>
                </div>
              </div>
            )
          })}

          {((answers.length === 0) || (answers.length < Questions.find(q => q.type === forperson).questions.length)) ?
            (<button type="button" className="border border-gray-200 dark:border-gray-700 flex justify-center items-center rounded-lg px-10 py-[9px] text-gray-500 dark:text-gray-400 lg:mr-7 mr-0 lg:mb-0 mb-4 mt-4" disabled={true}>
              Submit Answers
            </button>)
            :
            (<button type="button" className="bg-blue-500 text-white hover:bg-blue-600 border border-gray-200 dark:border-gray-700 flex justify-center items-center rounded-lg px-10 py-[9px] lg:mr-7 mr-0 lg:mb-0 mb-4 mt-4" onClick={() => setEvaluate(true)}>Submit Answers</button>)
          }
        </div>
      </div>
    )
  }

  const getDetails = () => {
    let messageparts = {};

    if (forperson !== "" && forperson === "myself" && answers.filter(item => item.answer === 'Yes').length >= 2)
      messageparts = ResultResponses.filter(item => item.type === 'myself' && item.response === 'yes')[0].message.split("||");
    else if (forperson !== "" && forperson === "myself" && answers.filter(item => item.answer === 'Yes').length < 2)
      messageparts = ResultResponses.filter(item => item.type === 'myself' && item.response === 'no')[0].message.split("||");
    else if (forperson !== "" && forperson === "lovedone" && answers.filter(item => item.answer === 'Yes').length >= 2)
      messageparts = ResultResponses.filter(item => item.type === 'lovedone' && item.response === 'yes')[0].message.split("||");
    else if (forperson !== "" && forperson === "lovedone" && answers.filter(item => item.answer === 'Yes').length < 2)
      messageparts = ResultResponses.filter(item => item.type === 'lovedone' && item.response === 'no')[0].message.split("||");

    return messageparts.map((part, index) => (
      <p key={index} className="text-gray-500 text-base dark:text-gray-300 mb-5">{part}</p>
    ));
  }

  const bindHandyCollapse = () => {
    appendScript("/js/plugins-core/handy-collapse.js", "handy-collapse");
    setTimeout(() => {
      appendScript("/js/plugins-script/handy-collapse.js", "handy-collapse-function");
    }, 100)
  }

  const assessmentResult = () => {
    return (
      <div className="rounded-lg shadow-fornt-2 dark:bg-foreground bg-white mb-7 border border-gray-200 dark:border-gray-700">
        <div className="bg-blue-500 text-white font-medium mr-2 lg:text-3xl text-2xl p-3.5" style={{ marginRight: "0px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
          <div className="text-center">Substance Abuse Evaluation Results</div>
        </div>
        <div className="p-6">

          <p className="text-base text-gray-800 dark:text-gray-200 mb-2">
            Generally, professionals look for the presence of 2 or more of these criteria being present in a 12-month period when evaluating for a substance use disorder.
          </p>

          <p className="bg-blue-500 text-white font-medium text-lg py-4 px-2">
            You answered YES: {answers.filter(item => item.answer === 'Yes').length} of {answers.length} criteria
          </p>

          <div className="mt-4">
            <button type="button" data-hc-control="content01" aria-expanded="false" className="px-5 py-2 w-full text-left border-b border-t border-gray-200 dark:border-foreground accordion-border-bottom-none flex justify-between text-base lg:text-lg font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent01">
              Details
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 ml-5 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            <div id="basicContent01" className="p-5 border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content01" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
              {/* <div className="pt-3 dark:text-gray-400 text-gray-500 leading-[24px]">
                          <b>[1]</b>. National Institute on Alcohol Abuse and Alcoholism. (2020). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.niaaa.nih.gov/publications/brochures-and-fact-sheets/alcohol-facts-and-statistics">Alcohol Facts and Statistics</a>.
                        </div> */}
              {getDetails()}
              {bindHandyCollapse()}
              {/* <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mt-2 mb-5">
                Learn about opioids and substance abuse
              </h4>

              <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Opiates, which are typically referred to as narcotics, are most commonly used for pain relief and to induce sleep. These drugs are originally derived from the seeds of poppy plants or their byproducts. Most opiates are synthetic, but some naturally occurring forms include opium and morphine.</p> */}
            </div>
          </div>

        </div>
      </div>
    )
  }

  return (
    <>
      <Header />

      <PageContentContainer>

        <>
          <section className="dark:bg-background mb-12">
            <div className="relative">
              <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                <h1 className="capitalize text-4xl text-white font-semibold">Am I Addicted To Opiods</h1>
                <p className="text-white mt-2">Learn the basics of substance abuse assessments</p>
              </div>
            </div>
          </section>

          <div className="container mx-auto px-5">
            <div className="grid grid-cols-3 gap-7 pb-10">

              <div className="lg:col-span-2 col-span-3">

                <MedicalReview type={"mobile"} />

                <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building.jpg" alt="" /> */}
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-3.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-4.jpg" alt="" /> */}
                  <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      Assessment of Substance Abuse
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-6">Use our drug abuse assessment to find out more about the possible severity of addiction for you or a loved one in less than five minutes.</p>


                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5 pt-5">Healthcare experts have created and employed the 11 questions listed below. Your answers will be kept completely private and anonymous.</p>
                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Kindly carefully read the following questions and provide the most honest response you are able to. </p>

                    {!beginAssessment ? selfAssessment() : (!evaluate && startAssessment())}

                    {evaluate && assessmentResult()}

                    <button type="button" data-hc-control="content01" aria-expanded="false" className="px-5 py-2 w-full text-left border-b border-t border-gray-200 dark:border-foreground accordion-border-bottom-none flex justify-between text-base lg:text-lg font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent01">
                      What is an evaluation for substance abuse?
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 ml-5 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    <div id="basicContent01" className="px-5 border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content01" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
                      {/* <div className="pt-3 dark:text-gray-400 text-gray-500 leading-[24px]">
                          <b>[1]</b>. National Institute on Alcohol Abuse and Alcoholism. (2020). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.niaaa.nih.gov/publications/brochures-and-fact-sheets/alcohol-facts-and-statistics">Alcohol Facts and Statistics</a>.
                        </div> */}

                      <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Eleven carefully chosen yes/no questions make up this online drug abuse self-test, which may be used as an educational tool to determine the likelihood and severity of a substance use issue. It is not appropriate to use this tool to diagnose; instead, it should be used to answer questions about you or a loved one.</p>
                    </div>

                    <button type="button" data-hc-control="content02" style={{ marginTop: "-2px" }} aria-expanded="false" className="px-5 py-2 w-full text-left border-b border-t border-gray-200 dark:border-foreground accordion-border-bottom-none flex justify-between text-base lg:text-lg font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent02">
                      Is the self-assessment for substance abuse free?
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 ml-5 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    <div id="basicContent02" className="px-5 border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content02" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
                      {/* <div className="pt-3 dark:text-gray-400 text-gray-500 leading-[24px]">
                          <b>[1]</b>. National Institute on Alcohol Abuse and Alcoholism. (2020). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.niaaa.nih.gov/publications/brochures-and-fact-sheets/alcohol-facts-and-statistics">Alcohol Facts and Statistics</a>.
                        </div> */}

                      <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Yes, the addiction assessment is free of charge, private, and requires no personal information to be entered to obtain the findings. However, at the bottom of the results page is a phone number where an admissions counselor may assist you if you do decide you would like to talk about treatment choices.</p>
                    </div>

                    <button type="button" data-hc-control="content03" style={{ marginTop: "-2px" }} aria-expanded="false" className="px-5 py-2 w-full text-left border-b border-t border-gray-200 dark:border-foreground accordion-border-bottom-none flex justify-between text-base lg:text-lg font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent03">
                      Which questions are included in this online evaluation of drug use?
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 ml-5 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    <div id="basicContent03" className="px-5 border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content03" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
                      {/* <div className="pt-3 dark:text-gray-400 text-gray-500 leading-[24px]">
                          <b>[1]</b>. National Institute on Alcohol Abuse and Alcoholism. (2020). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.niaaa.nih.gov/publications/brochures-and-fact-sheets/alcohol-facts-and-statistics">Alcohol Facts and Statistics</a>.
                        </div> */}

                      <p className="text-gray-500 text-base dark:text-gray-300 mb-5">You will be asked questions about your usage and well-being during the drug abuse and addiction examination. Our drug and alcohol use evaluation may include questions about behavioral, mental, or physical symptoms of addiction. Some examples of evaluation questions are as follows:</p>

                      <ul className=" mb-5">
                        <li className="mb-0">
                          <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                            </svg>
                            1. Do you frequently use drugs or alcohol more frequently than you wanted to, or for longer periods?
                          </div>
                        </li>
                        <li className="mb-0">
                          <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                            </svg>
                            2. Have you been wanting to reduce your use of drugs or alcohol for some time now, or have you tried and failed to do so?
                          </div>
                        </li>
                        <li className="mb-0">
                          <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                            </svg>
                            3. How much time do you spend looking for, using, or getting well from drugs or alcohol?
                          </div>
                        </li>
                        <li className="mb-0">
                          <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                            </svg>
                            4. Do you have intense cravings or temptations to consume alcohol or drugs?
                          </div>
                        </li>
                      </ul>
                    </div>

                    {/* <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi voluptates accusantium molestias magnam beatae modi dolores corrupti aliquam saepe fugit, vero repudiandae praesentium eum iste quia numquam ipsum, reiciendis consectetur!
                      Illo velit distinctio magnam corrupti quasi vero reprehenderit. Ab assumenda enim ipsam quia velit facere, maxime cumque eveniet eligendi ea officiis! Optio totam sit animi hic dolorum obcaecati illum asperiores.
                      Impedit deserunt illo accusantium? Nobis ex modi cum hic sed distinctio possimus placeat quos nam aut. Laborum obcaecati quis, rem exercitationem eum aliquam non? At magnam placeat molestias cum tempora!
                      Rerum sint praesentium voluptas deserunt officia nobis, porro recusandae necessitatibus omnis vero beatae perspiciatis expedita aperiam reiciendis eaque nostrum quis consequuntur iste eum ad sequi corrupti! Explicabo nobis sit praesentium.</p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam vel ea sunt molestias facere quidem! Unde, repellendus dolores neque voluptatibus id, sunt impedit, nobis molestiae quisquam aut aliquid rem sed.
                      Doloremque, voluptatum voluptas iure facere, laudantium molestiae sint quam corporis illum ad amet recusandae vitae ea, accusamus dicta! Expedita accusantium quo reprehenderit! Repellendus quod eos tenetur nesciunt deleniti, cumque enim.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda facilis eum aliquam delectus deleniti corrupti nulla veniam cumque velit, veritatis eos quia dolorum enim ut quidem? Beatae officia aliquam voluptas!
                      Dolorum quia at maxime aliquam distinctio, molestias ratione nesciunt quod cupiditate laudantium aspernatur consequatur eaque vero id eius rerum libero dolore nobis modi. Dicta, illum architecto expedita explicabo laboriosam perferendis.
                      Debitis maiores pariatur enim voluptatum? Optio ipsa dignissimos, dolore, debitis adipisci veniam sed et labore, odio ab incidunt nihil officiis temporibus ut nemo aliquid ad eos rerum qui deserunt ipsam!</p> */}
                  </div>
                </div>

                <VisitOurFacility />
                
              </div>

              <div className="lg:col-span-1 col-span-3">

                <MedicalReview type={"desktop"} />

                <RehabSearchForm />

                {/* {featuredListing()} */}
                {<FeaturedListing items={5} />}

              </div>

            </div>
          </div>

        </>

        <TrendingListing />

        <ScrollToTopButton />

        <Footer />
      </PageContentContainer>

    </>
  )
}

export default AmIAddictedToOpioids