import React from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

import ScrollToTopButton from '../../components/ScrollToTopButton';

// import businesses_list from "../../../business";
import FeaturedListing from '../../components/FeaturedListing';
import RehabSearchForm from '../../components/RehabSearchForm';
import MedicalReview from '../../components/MedicalReview';
import VisitOurFacility from '../../components/VisitOurFacility';
import TrendingListing from "../../components/TrendingListing";

const SobrietyEngine = () => {

  return (
    <>
      <Header />

      <PageContentContainer>

        <>
          <section className="dark:bg-background mb-12">
            <div className="relative">
              <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                <h1 className="capitalize text-4xl text-white font-semibold">Sobriety Engine</h1>
                <p className="text-white mt-2">Your Path to Sustainable Recovery</p>
              </div>
            </div>
          </section>

          <div className="container mx-auto px-5">
            <div className="grid grid-cols-3 gap-7 pb-10">

              <div className="lg:col-span-2 col-span-3">

                <MedicalReview type={"mobile"} />

                <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" /> */}
                  <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      What Is Sobriety Engine?
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Opioid Rehab emphasizes the importance of long-term sobriety from opioid addiction, a crucial step towards a healthier life. Sobriety Engine is a platform designed to help individuals connect with resources, support, and rehab centers for ongoing recovery. It aims to empower individuals to overcome addiction and stay sober by providing essential tools and support systems for their journey to lasting recovery.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Sobriety Engine is a recovery platform within the Opioid Rehab directory that connects users to top-rated rehab centers offering evidence-based treatment, aftercare planning, and ongoing support services. It helps individuals find inpatient and outpatient programs, as well as a community that understands their struggles. Sobriety Engine believes in the power of community and support, offering an extensive network of rehab programs and holistic recovery plans tailored to individual needs.</p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      The Importance of a Long-Term Recovery Plan
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Staying sober after treatment requires continuous effort, personal growth, and access to ongoing care. Many individuals relapse due to a lack of post-rehab support. Sobriety Engine provides a comprehensive recovery plan that includes customized aftercare programs, support groups and counseling, and sober living options. These programs help transition individuals from treatment back into daily life, maintain accountability and motivation, and provide an in-between step before returning home. Top rehab centers offer customized aftercare plans to help individuals transition back into their daily lives.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Sobriety Engine helps individuals find rehab centers that prioritize long-term recovery, offering extended care services, relapse prevention strategies, and holistic wellness approaches. It connects users with a community of support through online communities, therapy groups, and alumni programs. It provides access to practical tools and resources for ongoing recovery, including relapse prevention workshops, mindfulness techniques, and educational resources. It also allows users to track their progress and achievements, with many rehab centers offering apps to reinforce their commitment to sobriety.</p>
                  </div>
                </div>

                <VisitOurFacility />
                
              </div>

              <div className="lg:col-span-1 col-span-3">

                <MedicalReview type={"desktop"} />

                <RehabSearchForm />

                {/* {featuredListing()} */}
                {<FeaturedListing items={5} />}

              </div>

            </div>
          </div>

        </>

        <TrendingListing />

        <ScrollToTopButton />

        <Footer />
      </PageContentContainer>

    </>
  )
}

export default SobrietyEngine