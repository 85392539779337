import React, { useState, useEffect } from 'react'

import { Link, useNavigate } from 'react-router-dom'
import { getCookie, checkRole, eraseCookie } from '../../func';

const MobileNav = () => {
    const navigate = useNavigate();

    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [userRole, setUserRole] = useState('');

    useEffect(() => {
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            //user is loggedin
            setUserLoggedIn(true);
            setUserRole(checkRole(getCookie("token")));
        }

        // Respond to the `storage` event
        function storageEventHandler(event) {
            if (localStorage.getItem("isLoggedIn") !== null) {
                setUserLoggedIn(true);
                setUserRole(checkRole(getCookie("token")));
            }
            else {
                setUserLoggedIn(false);
                setUserRole('');
            }
        }
        // Hook up the event handler
        window.addEventListener("storage", storageEventHandler);
        return () => {
            // Remove the handler when the component unmounts
            window.removeEventListener("storage", storageEventHandler);
        };
    }, [])

    const logout = () => {
        setTimeout(() => {
            localStorage.removeItem("isLoggedIn");
            eraseCookie("token");
            // navigate(`/admin/login`, { replace: true });
            navigate(`/login`, { replace: true });
        }, 500);
    }

    const CreateRoleBasedNavigation = () => {
        if (userRole === "admin") {
            return <>
                <div className="dropdown dropdown-mobile dark:bg-foreground rounded-lg" data-dropdown>
                    <button className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-between items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200" data-dropdown-button>
                        <div className="flex items-center">
                            <div className="relative lg:mr-3 mr-3">
                                <img className="w-8 h-8 rounded-full border border-white" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAMAAAANmfvwAAAAMFBMVEX////S19vR1try8vIAAADQ1dnZ3eDf4uXl5+rW2t75+vrt7/D19vfn6uzx8vQYGShNh6tnAAAA5UlEQVQ4jd2SSQLDIAhFhQZn7f1vWzWolHTYdFVW4ecxijG/t1qcta7Ud/+jo2GIRC6+IjwhEPjiQ/tA8lciEACdFart30ETgE3N08uNQVA5GoEid4DmP+XxLUokMcZhF0RM7ARQVIhUTgGdRoQykgDajdiBAE0/XZHASJrNsi/ajRw1G3aMiKfJjMxmuDCWjejajADsGQM8I1xoNzeT7ELcrhhp5l3tVo5ZM21hTaCRtAS17q1k3cpeVFBp5cOWcQyw1ltpHINYVB+BIEmhXbJ8s7GrYpT5y/H+j92+mjma3Y9P9gDGnQaRgDV0MgAAAABJRU5ErkJggg==" data-dropdown-button="true" alt="" />
                                <div className="w-2 h-2 bg-green-500 rounded-full border absolute bottom-0 right-0"></div>
                            </div>
                            My Account
                        </div>
                        <svg className="w-3 h-auto ml-2 transition-all" width={10} height={6} data-dropdown-button viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                        </svg>
                    </button>
                    <div className="dropdown-mobile-menu dark:bg-foreground dark:text-gray-300 text-gray-700">
                        <div className="dropdown-links">
                            <ul className="p-4">
                                <li>
                                    <Link to={"/admin/dashboard"} className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-start items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200">
                                        <svg className="w-4 h-4 mr-2" width={20} height={20} fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="currentColor" d="M7.143 2c.946 0 1.714.746 1.714 1.667v5.555c0 .92-.768 1.667-1.714 1.667H3.714C2.768 10.889 2 10.142 2 9.222V3.667C2 2.747 2.768 2 3.714 2h3.429Zm0 11.111c.946 0 1.714.747 1.714 1.667v1.11c0 .921-.768 1.668-1.714 1.668H3.714c-.946 0-1.714-.747-1.714-1.667v-1.111c0-.92.768-1.667 1.714-1.667h3.429Z" />
                                            <path fill="#B2DDFF" d="M16.286 2C17.232 2 18 2.746 18 3.667v1.11c0 .92-.768 1.667-1.714 1.667h-3.429c-.946 0-1.714-.746-1.714-1.666V3.667c0-.92.768-1.667 1.714-1.667h3.429Zm0 6.667c.946 0 1.714.746 1.714 1.666v5.556c0 .92-.768 1.667-1.714 1.667h-3.429c-.946 0-1.714-.747-1.714-1.667v-5.556c0-.92.768-1.666 1.714-1.666h3.429Z" />
                                        </svg>
                                        Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/admin/profile"} className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-start items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200">
                                        <svg className="w-4 h-4 mr-2" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.8296 11.6H8.57036C5.49461 11.6 3 13.988 3 16.9331C3 17.5221 3.49886 18 4.11407 18H16.2866C16.9018 18.0009 17.4 17.5239 17.4 16.9331C17.4 13.988 14.9057 11.6 11.8296 11.6Z" fill="currentColor" />
                                            <path d="M14.2 6C14.2 8.20906 12.4094 10 10.2 10C7.99064 10 6.20001 8.20937 6.20001 6C6.20001 3.79063 7.99095 2 10.2 2C12.4094 2 14.2 3.79094 14.2 6Z" fill="#D0D5DD" />
                                        </svg>
                                        My Profile
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/admin/change_password"} className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-start items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200">
                                        <svg className="mr-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                                        </svg>
                                        Change Password
                                    </Link>
                                </li>
                                <li>
                                    <div className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-start items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200" style={{ cursor: "pointer" }} onClick={() => logout()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                        </svg>
                                        Sign Out
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        }
        else if (userRole === "member") {
            return <>
                <div className="dropdown dropdown-mobile dark:bg-foreground rounded-lg" data-dropdown>
                    <button className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-between items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200" data-dropdown-button>
                        <div className="flex items-center">
                            <div className="relative lg:mr-3 mr-3">
                                <img className="w-8 h-8 rounded-full border border-white" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAMAAAANmfvwAAAAMFBMVEX////S19vR1try8vIAAADQ1dnZ3eDf4uXl5+rW2t75+vrt7/D19vfn6uzx8vQYGShNh6tnAAAA5UlEQVQ4jd2SSQLDIAhFhQZn7f1vWzWolHTYdFVW4ecxijG/t1qcta7Ud/+jo2GIRC6+IjwhEPjiQ/tA8lciEACdFart30ETgE3N08uNQVA5GoEid4DmP+XxLUokMcZhF0RM7ARQVIhUTgGdRoQykgDajdiBAE0/XZHASJrNsi/ajRw1G3aMiKfJjMxmuDCWjejajADsGQM8I1xoNzeT7ELcrhhp5l3tVo5ZM21hTaCRtAS17q1k3cpeVFBp5cOWcQyw1ltpHINYVB+BIEmhXbJ8s7GrYpT5y/H+j92+mjma3Y9P9gDGnQaRgDV0MgAAAABJRU5ErkJggg==" data-dropdown-button="true" alt="" />
                                <div className="w-2 h-2 bg-green-500 rounded-full border absolute bottom-0 right-0"></div>
                            </div>
                            My Account
                        </div>
                        <svg className="w-3 h-auto ml-2 transition-all" width={10} height={6} data-dropdown-button viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                        </svg>
                    </button>
                    <div className="dropdown-mobile-menu dark:bg-foreground dark:text-gray-300 text-gray-700">
                        <div className="dropdown-links">
                            <ul className="p-4">
                                <li>
                                    <Link to={"/admin/add_listing"} className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-start items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200">
                                        <svg className="w-4 h-4 mr-2" width={20} height={20} fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="currentColor" d="M7.143 2c.946 0 1.714.746 1.714 1.667v5.555c0 .92-.768 1.667-1.714 1.667H3.714C2.768 10.889 2 10.142 2 9.222V3.667C2 2.747 2.768 2 3.714 2h3.429Zm0 11.111c.946 0 1.714.747 1.714 1.667v1.11c0 .921-.768 1.668-1.714 1.668H3.714c-.946 0-1.714-.747-1.714-1.667v-1.111c0-.92.768-1.667 1.714-1.667h3.429Z" />
                                            <path fill="#B2DDFF" d="M16.286 2C17.232 2 18 2.746 18 3.667v1.11c0 .92-.768 1.667-1.714 1.667h-3.429c-.946 0-1.714-.746-1.714-1.666V3.667c0-.92.768-1.667 1.714-1.667h3.429Zm0 6.667c.946 0 1.714.746 1.714 1.666v5.556c0 .92-.768 1.667-1.714 1.667h-3.429c-.946 0-1.714-.747-1.714-1.667v-5.556c0-.92.768-1.666 1.714-1.666h3.429Z" />
                                        </svg>
                                        Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/admin/profile"} className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-start items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200">
                                        <svg className="w-4 h-4 mr-2" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.8296 11.6H8.57036C5.49461 11.6 3 13.988 3 16.9331C3 17.5221 3.49886 18 4.11407 18H16.2866C16.9018 18.0009 17.4 17.5239 17.4 16.9331C17.4 13.988 14.9057 11.6 11.8296 11.6Z" fill="currentColor" />
                                            <path d="M14.2 6C14.2 8.20906 12.4094 10 10.2 10C7.99064 10 6.20001 8.20937 6.20001 6C6.20001 3.79063 7.99095 2 10.2 2C12.4094 2 14.2 3.79094 14.2 6Z" fill="#D0D5DD" />
                                        </svg>
                                        My Profile
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/admin/change_password"} className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-start items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200">
                                        <svg className="mr-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                                        </svg>
                                        Change Password
                                    </Link>
                                </li>
                                <li>
                                    <div className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-start items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200" style={{ cursor: "pointer" }} onClick={() => logout()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                        </svg>
                                        Sign Out
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        }
        else if (userRole === "author") {
            return <>
                <div className="dropdown dropdown-mobile dark:bg-foreground rounded-lg" data-dropdown>
                    <button className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-between items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200" data-dropdown-button>
                        <div className="flex items-center">
                            <div className="relative lg:mr-3 mr-3">
                                <img className="w-8 h-8 rounded-full border border-white" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAMAAAANmfvwAAAAMFBMVEX////S19vR1try8vIAAADQ1dnZ3eDf4uXl5+rW2t75+vrt7/D19vfn6uzx8vQYGShNh6tnAAAA5UlEQVQ4jd2SSQLDIAhFhQZn7f1vWzWolHTYdFVW4ecxijG/t1qcta7Ud/+jo2GIRC6+IjwhEPjiQ/tA8lciEACdFart30ETgE3N08uNQVA5GoEid4DmP+XxLUokMcZhF0RM7ARQVIhUTgGdRoQykgDajdiBAE0/XZHASJrNsi/ajRw1G3aMiKfJjMxmuDCWjejajADsGQM8I1xoNzeT7ELcrhhp5l3tVo5ZM21hTaCRtAS17q1k3cpeVFBp5cOWcQyw1ltpHINYVB+BIEmhXbJ8s7GrYpT5y/H+j92+mjma3Y9P9gDGnQaRgDV0MgAAAABJRU5ErkJggg==" data-dropdown-button="true" alt="" />
                                <div className="w-2 h-2 bg-green-500 rounded-full border absolute bottom-0 right-0"></div>
                            </div>
                            My Account
                        </div>
                        <svg className="w-3 h-auto ml-2 transition-all" width={10} height={6} data-dropdown-button viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                        </svg>
                    </button>
                    <div className="dropdown-mobile-menu dark:bg-foreground dark:text-gray-300 text-gray-700">
                        <div className="dropdown-links">
                            <ul className="p-4">
                                <li>
                                    <Link to={"/admin/add_post"} className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-start items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200">
                                        <svg className="w-4 h-4 mr-2" width={20} height={20} fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="currentColor" d="M7.143 2c.946 0 1.714.746 1.714 1.667v5.555c0 .92-.768 1.667-1.714 1.667H3.714C2.768 10.889 2 10.142 2 9.222V3.667C2 2.747 2.768 2 3.714 2h3.429Zm0 11.111c.946 0 1.714.747 1.714 1.667v1.11c0 .921-.768 1.668-1.714 1.668H3.714c-.946 0-1.714-.747-1.714-1.667v-1.111c0-.92.768-1.667 1.714-1.667h3.429Z" />
                                            <path fill="#B2DDFF" d="M16.286 2C17.232 2 18 2.746 18 3.667v1.11c0 .92-.768 1.667-1.714 1.667h-3.429c-.946 0-1.714-.746-1.714-1.666V3.667c0-.92.768-1.667 1.714-1.667h3.429Zm0 6.667c.946 0 1.714.746 1.714 1.666v5.556c0 .92-.768 1.667-1.714 1.667h-3.429c-.946 0-1.714-.747-1.714-1.667v-5.556c0-.92.768-1.666 1.714-1.666h3.429Z" />
                                        </svg>
                                        Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/admin/profile"} className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-start items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200">
                                        <svg className="w-4 h-4 mr-2" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.8296 11.6H8.57036C5.49461 11.6 3 13.988 3 16.9331C3 17.5221 3.49886 18 4.11407 18H16.2866C16.9018 18.0009 17.4 17.5239 17.4 16.9331C17.4 13.988 14.9057 11.6 11.8296 11.6Z" fill="currentColor" />
                                            <path d="M14.2 6C14.2 8.20906 12.4094 10 10.2 10C7.99064 10 6.20001 8.20937 6.20001 6C6.20001 3.79063 7.99095 2 10.2 2C12.4094 2 14.2 3.79094 14.2 6Z" fill="#D0D5DD" />
                                        </svg>
                                        My Profile
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/admin/change_password"} className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-start items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200">
                                        <svg className="mr-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                                        </svg>
                                        Change Password
                                    </Link>
                                </li>
                                <li>
                                    <div className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-start items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200" style={{ cursor: "pointer" }} onClick={() => logout()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                        </svg>
                                        Sign Out
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        }
    }

    return (
        <div className="mobile-navbar overflow-y-auto fixed bg-white dark:bg-background top-0 h-full w-full transition-all z-[100] -left-full">
            <div className="flex justify-end py-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-gray-500 close-navbar-btn" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
            <ul className="mx-5">
                {/* <li>
                    <div className="dropdown dropdown-mobile dark:bg-foreground rounded-lg" data-dropdown>
                        <button className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-between items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200" data-dropdown-button>
                            Dashboard
                            <svg className="w-3 h-auto ml-2 transition-all" width={10} height={6} data-dropdown-button viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                            </svg>
                        </button>
                        <div className="dropdown-mobile-menu dark:bg-foreground dark:text-gray-300 text-gray-700">
                            <div className="dropdown-links">
                                <ul className="p-4">
                                    <li>
                                        <a href="dashboard-home.html" className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">
                                            Overview</a>
                                    </li>
                                    <li>
                                        <a href="dashboard-addlisting.html" className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Add Listing One</a>
                                    </li>
                                    <li>
                                        <a href="dashboard-addlisting-2.html" className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Add Listing Two</a>
                                    </li>
                                    <li>
                                        <a href="dashboard-booking.html" className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Booking</a>
                                    </li>
                                    <li>
                                        <a href="dashboard-bookmarks.html" className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Bookmarks</a>
                                    </li>
                                    <li>
                                        <a href="dashboard-bookmarks.html" className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Messages</a>
                                    </li>
                                    <li>
                                        <a href="dashboard-mylisting.html" className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Mylisting</a>
                                    </li>
                                    <li>
                                        <a href="dashboard-myprofile.html" className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">My Profile</a>
                                    </li>
                                    <li>
                                        <a href="dashboard-reviews.html" className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-0">Reviews</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li> */}

                <li>
                    <Link to={"/"} className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-between items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200">Home</Link>
                </li>

                <li>
                    <div className="dropdown dropdown-mobile dark:bg-foreground rounded-lg" data-dropdown>
                        <button className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-between items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200" data-dropdown-button>
                            <Link to={"/opioid-addiction"}>Opioid Addiction</Link>
                            <svg className="w-3 h-auto ml-2 transition-all" width={10} height={6} data-dropdown-button viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                            </svg>
                        </button>
                        <div className="dropdown-mobile-menu dark:bg-foreground dark:text-gray-300 text-gray-700">
                            <div className="dropdown-links">
                                <ul className="p-4">
                                    <li>
                                        <Link to={"/signs-of-opioid-addiction"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Signs of Opioid Addiction</Link>
                                    </li>
                                    <li>
                                        <Link to={"/effects-of-opioid-dependency"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Effects of Opioid Dependency</Link>
                                    </li>
                                    <li>
                                        <Link to={"/signs-of-opioid-overdose"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Signs of Opioid Overdose</Link>
                                    </li>
                                    <li>
                                        <Link to={"/opioid-addiction-risk-factors"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Opioid Addiction Risk Factors</Link>
                                    </li>
                                    <li>
                                        <Link to={"/am-i-addicted-to-opioids"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Am I Addicted to Opioids?</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>

                <li>
                    <div className="dropdown dropdown-mobile dark:bg-foreground rounded-lg" data-dropdown>
                        <button className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-between items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200" data-dropdown-button>
                            <Link to={"/find-opioid-treatment"}>Find Opioid Treatment</Link>
                            <svg className="w-3 h-auto ml-2 transition-all" width={10} height={6} data-dropdown-button viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                            </svg>
                        </button>
                        <div className="dropdown-mobile-menu dark:bg-foreground dark:text-gray-300 text-gray-700">
                            <div className="dropdown-links">
                                <ul className="p-4">
                                    <li>
                                        <Link to={"/detox"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Detox</Link>
                                    </li>
                                    <li>
                                        <Link to={"/residential-treatment"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Residential Treatment</Link>
                                    </li>
                                    <li>
                                        <Link to={"/medication-assisted-treatment"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Medication-Assisted Treatment</Link>
                                    </li>
                                    <li>
                                        <Link to={"/virtual-treatment"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3"> Virtual Treatment</Link>
                                    </li>
                                    <li>
                                        <Link to={"/outpatient-treatment"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Outpatient Treatment</Link>
                                    </li>
                                    <li>
                                        <Link to={"/paying-for-treatment"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Paying For Treatment</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>

                <li>
                    <div className="dropdown dropdown-mobile dark:bg-foreground rounded-lg" data-dropdown>
                        <button className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-between items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200" data-dropdown-button>
                            <Link to={"/success-stories-recovery"}>Success Stories + Recovery</Link>
                            <svg className="w-3 h-auto ml-2 transition-all" width={10} height={6} data-dropdown-button viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                            </svg>
                        </button>
                        <div className="dropdown-mobile-menu dark:bg-foreground dark:text-gray-300 text-gray-700">
                            <div className="dropdown-links">
                                <ul className="p-4">
                                    <li>
                                        <Link to={"/opioid-addiction-stories"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Opioid Addiction Stories</Link>
                                    </li>
                                    <li>
                                        <Link to={"/sobriety-engine"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Sobriety Engine</Link>
                                    </li>
                                    <li>
                                        <Link to={"/recovery-resources"} className="hover:text-blue-500 text-sm block dark:hover:text-blue-500 pb-3">Recovery Resources</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>

                <li>
                    <Link to={"/about-us"} className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-between items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200">About Us</Link>
                </li>

                <li>
                    <Link to={"/contact-us"} className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-between items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200">Contact Us</Link>
                </li>

                <li>
                    <Link to={"/blog"} className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-between items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200">Blog</Link>
                </li>

                {
                    userLoggedIn
                        ?
                        (
                            <CreateRoleBasedNavigation />
                        )
                        :
                        (
                            <>
                                <li>
                                    <Link to={"/login"} className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-between items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200">Login</Link>
                                </li>
                                <li>
                                    <Link to={"/register"} className="link w-full py-3 flex dark:border-gray-800 pb-5 justify-between items-center border-b border-gray-200 font-normal text-gray-500 dark:text-gray-200">Register</Link>
                                </li>
                            </>
                        )
                }

            </ul>
        </div>
    )
}

export default MobileNav