import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Alert from '../../../components/Alert';

import { Link, useNavigate, useParams } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { getCookie, checkRole, base64_decode, roleBasedBreadCrumbUrl } from '../../../func';
import AdminSideBar from '../../../components/AdminSideBar';
import AdminHeader from '../../../components/AdminHeader';

const initialOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENTID,
    //clientId: paypalClientID !== "" ? paypalClientID : process.env.REACT_APP_PAYPAL_CLIENTID,
    currency: process.env.REACT_APP_PAYPAL_CURRENCY,//"USD",
    intent: "capture",
    components: "buttons",
    //"disable-funding": "credit,card,venmo,paylater"
    "disable-funding": "credit,venmo,paylater"
};

var tempListing_ID = undefined;

const ClaimListing = () => {
    const navigate = useNavigate();
    let params = useParams();
    const [listing_id, setListingID] = useState(0);
    const [user_id, setUserID] = useState(0);

    const [title, setTitle] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');

    const [phone, setPhone] = useState('');
    const [website, setWebsite] = useState('');

    const [treatmentTypePoints, setTreatmentTypePoints] = useState('');
    const [paymentInfoPoints, setPaymentInfoPoints] = useState('');
    const [specialityServicePoints, setSpecialityServicePoints] = useState('');

    const [address, setAddress] = useState('');
    const [description, setDescription] = useState('');
    const [paymentInfo, setPaymentInfo] = useState('');
    const [keyPoints, setKeyPoints] = useState('');

    const [images, setImages] = useState([]);
    const [rating, setRating] = useState(0);
    const [activeteListing, setActivateListing] = useState(0);

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const token = getCookie("token");

        if (token === null) {
            // navigate(`/admin/login`, { replace: true });
            navigate(`/login`, { replace: true });
        }
        else {
            if (getCookie("token") !== undefined && getCookie("token") !== null) {
                if (checkRole(getCookie("token")) === "admin" || checkRole(getCookie("token")) === "member") {
                    const parts = base64_decode(token).split("|");
                    setUserID(parts[0]);

                    if (params.listing_id !== undefined && params.listing_id !== null && params.listing_id.toString().trim() !== "") {
                        setListingID(params.listing_id);
                        fetchListingDetails(params.listing_id.toString().trim());
                    }
                    else {
                        navigate(`/admin/my_listing`, { replace: true });
                    }
                }
                else if (checkRole(getCookie("token")) === "author") {
                    navigate(`/admin/add_post`, { replace: true });
                }
                else {
                    navigate(`/login`, { replace: true });
                }
            }
        }
    }, []);

    useEffect(() => {
        if (address.trim() !== "") {
            let address_parts = address.split(",");
            if (address_parts.length > 0 && address_parts.length === 4) {
                setStreetAddress(address_parts[0].trim());
                setCity(address_parts[1].trim());
                setState(address_parts[2].trim());
                setPostalCode(address_parts[3].trim());
            }
        }
    }, [address]);

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 10000);
    }

    const removeImage = (i) => {
        let array = [...images];
        array.splice(i, 1);
        setImages(array);
    }

    const fetchListingDetails = (listingid) => {
        const json = JSON.stringify({ "listing_id": atob(listingid) });

        axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_listing_details`, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                //console.log(response.data.details);
                const { title, address, roadorstreet, city, state, postalCode, description, paymentInfoSummery, keyPoints, phone, website, images, treatmentTypePoints, paymentInfoPoints, specialityServicePoints, rating, active } = response.data.details;

                setTitle(title);
                setAddress(address);
                setStreetAddress(roadorstreet);
                setCity(city);
                setState(state);
                setPostalCode(postalCode);
                setDescription(description);
                setPaymentInfo(paymentInfoSummery.split("||").join("\n"));
                setKeyPoints(keyPoints.split("||").join("\n"));
                setPhone(phone);
                setWebsite(website);
                setImages(images !== "" ? JSON.parse(images) : "");
                setTreatmentTypePoints(treatmentTypePoints.split("||").join("\n"));
                setPaymentInfoPoints(paymentInfoPoints.split("||").join("\n"));
                setSpecialityServicePoints(specialityServicePoints.split("||").join("\n"));
                setRating(rating);
                setActivateListing(active);
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const handleHide = () => {
        setIsVisible(!isVisible);
    };

    const createOrder = (data, actions, err) => {
        tempListing_ID = listing_id;
        // console.log("tempListing_ID", tempListing_ID);
        return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
                {
                    description: "Claim Listing Fee",
                    amount: {
                        currency_code: "USD",
                        value: process.env.REACT_APP_CLAIM_LISTING_FEE,
                    },
                },
            ],
        });
    }

    const onApprove = async (data, actions) => {
        // console.log("onApprove data: ", data);
        // console.log("onApprove actions: ", actions);
        const order = await actions.order.capture();        
        // console.log("onApprove: ", order);

        //console.log("status", order["purchase_units"][0]["payments"]["captures"][0]["status"])
        //console.log("order", JSON.stringify(order));

        claimListing(order["purchase_units"][0]["payments"]["captures"][0]["status"], JSON.stringify(order));

        // console.log('order["status"]: ', order["status"])
        // console.log('order["purchase_units"][0]["payments"]["captures"][0]["id"]: ', order["purchase_units"][0]["payments"]["captures"][0]["id"])
        // console.log('order["purchase_units"][0]["payments"]["captures"][0]["status"]: ', order["purchase_units"][0]["payments"]["captures"][0]["status"]);

    }

    const onCancel = async (data) => {
        // console.log("onCancel data: ", data);
        console.log(data);
    }

    const onError = async (err) => {
        // console.log("onError err: ", err);
        console.log(err);
    }

    const claimListing = (status, payment_response) => {
        // debugger;
        var user_id = base64_decode(getCookie("token")).split("|")[0];

        var json = JSON.stringify({ "listing_id": atob(tempListing_ID), "payment_status": status, "payment_response": JSON.stringify(JSON.parse(payment_response)), "user_id": user_id });
        var url = `${process.env.REACT_APP_BASE_API}/api.php?action=claim_listing`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {

                // // console.log("currentListing", currentListing);
                // var tempMyListing = [...currentListing];
                // // console.log("tempMyListing", tempMyListing);
                // // console.log("tempMyListing.filter(l => l.listing_id === selectedListing)", tempMyListing.filter(l => l.listing_id === selectedListing));
                // //tempMyListing.filter(l => l.listing_id === selectedListing)[0]["verified"] = 1;
                // tempMyListing.filter(l => l.listing_id === selectedListing)[0]["verified"] = (status.toLowerCase() === "completed" ? 1 : 0);
                // setMyListing(tempMyListing);

                handleMessage({ type: 'success', message: response.data.message, show: true });

                setTimeout(()=>{
                    navigate("/admin/my_listing", { replace: true });
                }, 2000)
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }

            setIsVisible(false);
            tempListing_ID = undefined;
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    return (
        <>
            <AdminSideBar />
            <AdminHeader />

            <div className="dark:bg-background lg:ml-64 ml-0 mt-20 min-h-[calc(100vh-80px)] p-7">
                <section className="dark:bg-background">
                    <div className="container mx-auto">
                        <div className="alert alert-info" role="alert">
                            Provided details are currently uneditable. You can edit this listing by paying claiming fee. This is one time fee for claiming and verifying listing.
                        </div>
                        <div className="flex justify-between text-gray-400 text-sm items-center mb-4 mt-10">
                            <div className="flex">
                                {/* {
                                base64_decode(getCookie("token")).split("|")[3] === "admin"
                                    ?
                                    (<Link to={"/admin/dashboard"} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                                    :
                                    (<Link to={"/admin/add_listing"} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                            } */}
                                {
                                    getCookie("token") !== null && (<Link to={roleBasedBreadCrumbUrl(base64_decode(getCookie("token")).split("|")[3])} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                                }
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                </svg>
                                <span>Claim Listing</span>
                            </div>

                            <button type="button" className="flex items-center px-6 py-2 bg-blue-500 hover:bg-blue-600 shadow-glow-blue text-white rounded-lg" data-modal-target="paypal-payment-modal" data-modal-toggle="paypal-payment-modal" onClick={() => setIsVisible(!isVisible)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" className="w-3 h-auto text-blue-500 mr-1" >
                                    <path fill="#ffffff" d="M7.2 3H4.8L3.91 1.666a.3.3 0 0 1 .25-.466h3.678a.3.3 0 0 1 .25.466L7.199 3Zm-2.4.6h2.4c.071.047.152.1.227.157C8.507 4.438 10.8 5.904 10.8 9A1.8 1.8 0 0 1 9 10.8H3A1.8 1.8 0 0 1 1.2 9c0-3.096 2.293-4.562 3.356-5.243.092-.058.173-.11.244-.157Zm1.577 1.8a.38.38 0 0 0-.394-.377.374.374 0 0 0-.36.377v.112a1.238 1.238 0 0 0-.298.096c-.28.128-.523.364-.583.707a.893.893 0 0 0 .064.544c.078.165.2.28.324.365.217.148.504.235.724.3l.04.012c.263.08.44.138.55.219.047.034.064.06.07.077.007.015.018.049.005.124-.01.065-.047.121-.15.165-.114.048-.3.073-.54.035a4.162 4.162 0 0 1-.49-.146l-.12-.041a.375.375 0 0 0-.475.238.35.35 0 0 0 .238.457c.022.025.05.034.082.045.132.051.381.13.56.17V9c0 .208.168.377.36.377A.38.38 0 0 0 6.376 9v-.103c.1-.019.197-.064.287-.086a.957.957 0 0 0 .594-.726.952.952 0 0 0-.056-.553.923.923 0 0 0-.317-.383c-.227-.167-.53-.257-.758-.326l-.031-.004c-.25-.08-.43-.137-.544-.215-.049-.034-.064-.057-.068-.066-.003-.007-.015-.03-.003-.094.007-.037.035-.1.153-.152.105-.054.308-.084.537-.065.082.03.335.079.408.097a.373.373 0 0 0 .458-.266.373.373 0 0 0-.266-.457 6.343 6.343 0 0 0-.394-.083V5.4Z" />
                                </svg>
                                Pay Now To Claim Listing
                            </button>
                        </div>
                        <Alert type={alert.type} message={alert.message} show={alert.show} />
                        <div className="bg-white shadow-front-2 dark:bg-foreground rounded-lg p-6 mb-7">
                            <h4 className="text-gray-700 dark:text-gray-300 text-xl font-semibold mb-3">
                                Listing Information
                            </h4>
                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Listing Title <sup className="text-red-500">*</sup></label>
                                    <input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="Type a title" value={title} onChange={(e) => setTitle(e.target.value)} disabled />
                                </div>
                            </div>
                        </div>
                        <div className="bg-white shadow-front-2 dark:bg-foreground rounded-lg p-6 mb-7">
                            <h4 className="text-gray-700 dark:text-gray-300 text-xl font-semibold mb-3">
                                Locations
                            </h4>
                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                <div className="lg:col-span-2 col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Address <sup className="text-red-500">*</sup></label>
                                    <input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. Bloosom" value={address} onChange={(e) => setAddress(e.target.value)} disabled />
                                </div>
                                <div className="lg:col-span-1 col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Road or Street <sup className="text-red-500">*</sup></label>
                                    <input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. 12 San Pedro Street" value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} disabled />
                                </div>
                                <div className="lg:col-span-1 col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">City<sup className="text-red-500">*</sup></label>
                                    <input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. 12 San Pedro Street" value={city} onChange={(e) => setCity(e.target.value)} disabled />
                                </div>
                                <div className="lg:col-span-1 col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">State<sup className="text-red-500">*</sup></label>
                                    <div className="relative mb-3">
                                        <select name="state" className="bg-white w-full border border-gray-200 dark:bg-foreground dark:border-gray-800 relative appearance-none rounded-lg px-4 py-[0.969rem] focus:outline-blue-300 text-gray-400 focus:outline-none" value={state} onChange={(e) => setState(e.target.value)} disabled>
                                            <option value="-1">Select State</option>
                                            <option value="AL">Alabama, AL</option>
                                            <option value="AK">Alaska, AK</option>
                                            <option value="AZ">Arizona, AZ</option>
                                            <option value="AR">Arkansas, AR</option>
                                            <option value="CA">California, CA</option>
                                            <option value="CO">Colorado, CO</option>
                                            <option value="CT">Connecticut, CT</option>
                                            <option value="DE">Delaware, DE</option>
                                            <option value="DC">District Of Columbia, DC</option>
                                            <option value="FL">Florida, FL</option>
                                            <option value="GA">Georgia, GA</option>
                                            <option value="HI">Hawaii, HI</option>
                                            <option value="ID">Idaho, ID</option>
                                            <option value="IL">Illinois, IL</option>
                                            <option value="IN">Indiana, IN</option>
                                            <option value="IA">Iowa, IA</option>
                                            <option value="KS">Kansas, KS</option>
                                            <option value="KY">Kentucky, KY</option>
                                            <option value="LA">Louisiana, LA</option>
                                            <option value="ME">Maine, ME</option>
                                            <option value="MD">Maryland, MD</option>
                                            <option value="MA">Massachusetts, MA</option>
                                            <option value="MI">Michigan, MI</option>
                                            <option value="MN">Minnesota, MN</option>
                                            <option value="MS">Mississippi, MS</option>
                                            <option value="MO">Missouri, MO</option>
                                            <option value="MT">Montana, MT</option>
                                            <option value="NE">Nebraska, NE</option>
                                            <option value="NV">Nevada, NV</option>
                                            <option value="NH">New Hampshire, NH</option>
                                            <option value="NJ">New Jersey, NJ</option>
                                            <option value="NM">New Mexico, NM</option>
                                            <option value="NY">New York, NY</option>
                                            <option value="NC">North Carolina, NC</option>
                                            <option value="ND">North Dakota, ND</option>
                                            <option value="OH">Ohio, OH</option>
                                            <option value="OK">Oklahoma, OK</option>
                                            <option value="OR">Oregon, OR</option>
                                            <option value="PA">Pennsylvania, PA</option>
                                            <option value="RI">Rhode Island, RI</option>
                                            <option value="SC">South Carolina, SC</option>
                                            <option value="SD">South Dakota, SD</option>
                                            <option value="TN">Tennessee, TN</option>
                                            <option value="TX">Texas, TX</option>
                                            <option value="UT">Utah, UT</option>
                                            <option value="VT">Vermont, VT</option>
                                            <option value="VA">Virginia, VA</option>
                                            <option value="WA">Washington, WA</option>
                                            <option value="WV">West Virginia, WV</option>
                                            <option value="WI">Wisconsin, WI</option>
                                            <option value="WY">Wyoming, WY</option>
                                        </select>
                                        <svg width={10} height={6} className="w-3 h-3 text-gray-400 absolute right-3 top-1/2 -translate-y-1/2 z-10" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74713 0.525274 9.25335 0.525274 9.56583 0.826613C9.87832 1.12795 9.87832 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="lg:col-span-1 col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Zip Code<sup className="text-red-500">*</sup></label>
                                    <input type="number" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. ACB1258" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} disabled />
                                </div>
                            </div>
                        </div>
                        <div className="bg-white shadow-front-2 dark:bg-foreground rounded-lg p-6 mb-7">
                            <h4 className="text-gray-700 dark:text-gray-300 text-xl font-semibold mb-3">
                                Details
                            </h4>
                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Descriptions <sup className="text-red-500">*</sup></label>
                                    <textarea className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" rows={10} placeholder="Write your listing details here....." value={description} onChange={(e) => setDescription(e.target.value)} disabled />
                                </div>
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Payment Info Summery<sup className="text-red-500">*</sup></label>
                                    <textarea className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" rows={4} placeholder="Write your payment info here....." value={paymentInfo} onChange={(e) => setPaymentInfo(e.target.value)} disabled />
                                </div>
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Key Points <sup className="text-red-500">*</sup></label>
                                    <textarea className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" rows={4} placeholder="Write your listing key points here....." value={keyPoints} onChange={(e) => setKeyPoints(e.target.value)} disabled />
                                </div>
                                <div className="lg:col-span-1 col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Phone</label>
                                    <input type="phone" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. +1234567890" value={phone} onChange={(e) => setPhone(e.target.value)} disabled />
                                </div>
                                <div className="lg:col-span-1 col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Website</label>
                                    <input type="url" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. https://opioid-rehab.com/" value={website} onChange={(e) => setWebsite(e.target.value)} disabled />
                                </div>
                            </div>
                        </div>
                        <div className="bg-white shadow-front-2 dark:bg-foreground rounded-lg p-6 mb-7">
                            <h4 className="text-gray-700 dark:text-gray-300 text-xl font-semibold mb-3">
                                Media and Attachment
                            </h4>
                            {/* <div>
                                <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Photos or Images <sup className="text-red-500">*</sup></label>
                                <label htmlFor="upload-img" className="flex flex-col justify-center items-center cursor-pointer bg-gray-100 dark:bg-background dark:border-gray-800 border border-gray-200 rounded-lg py-10">
                                    <svg className="mb-2" width={43} height={42} viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_780_19100)">
                                            <path d="M9.95 35C4.73084 35 0.5 30.9688 0.5 26C0.5 22.075 3.13616 18.7375 6.81312 17.5063C6.80459 17.3375 6.8 17.1687 6.8 17C6.8 11.475 11.4987 7 17.3 7C21.1916 7 24.5844 9.01562 26.4022 12.0125C27.3997 11.3187 28.6072 11 29.9 11C33.3781 11 36.2 13.6312 36.2 17C36.2 17.7625 36.0491 18.4875 35.78 19.1625C39.6125 19.9 42.5 23.1313 42.5 27C42.5 31.4188 38.7397 35 34.1 35H9.95ZM15.1344 21.4375C14.5241 22.025 14.5241 22.975 15.1344 23.5063C15.7512 24.1438 16.7487 24.1438 17.3066 23.5063L19.925 21.1187V29.5C19.925 30.3312 20.6272 31 21.5 31C22.3728 31 23.075 30.3312 23.075 29.5V21.1187L25.6344 23.5063C26.2512 24.1438 27.2487 24.1438 27.8066 23.5063C28.4759 22.975 28.4759 22.025 27.8066 21.4375L22.5566 16.4375C21.9987 15.8562 21.0012 15.8562 20.3844 16.4375L15.1344 21.4375Z" fill="#98A2B3" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_780_19100">
                                                <rect width={42} height={42} fill="white" transform="translate(0.5)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <h6 className="text-base font-medium text-gray-700 dark:text-gray-400 mb-4">
                                        Drag and Drop Images
                                    </h6>
                                    <div className="text-base text-gray-700 dark:text-gray-400 mb-4">OR</div>
                                    <div className="bg-blue-500 text-white p-4 rounded-lg">Browse Images</div>
                                    <input type="file" id="upload-img" className="hidden" onChange={changeHandler} accept="image/x-png,image/gif,image/jpeg" multiple />
                                </label>
                            </div> */}
                            {(images !== "" && images.length > 0) && (<div style={{ display: "flex", overflow: "overlay", paddingBottom: "30px", paddingLeft: "8px" }}>
                                {images.map((image, index) => (
                                    <div className="mt-2 p-4 mr-2 card" style={{ display: "flex", alignItems: "center", minHeight: "100px" }} key={index}>
                                        <img style={{ maxWidth: "120px" }} src={image["base64"].includes(";base64,") ? image["base64"] : `${process.env.REACT_APP_BASE_API}/${image["base64"]}`} />
                                        <i className="fa fa-window-close ml-2" style={{ color: "red", marginBottom: "80px" }} onClick={() => removeImage(index)}></i>
                                    </div>
                                ))}

                            </div>)}
                        </div>
                        <div className="bg-white shadow-front-2 dark:bg-foreground rounded-lg p-6 mb-7">
                            <h4 className="text-gray-700 dark:text-gray-300 text-xl font-semibold mb-3">
                                Features Details
                            </h4>
                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Treatment Type<sup className="text-red-500">*</sup></label>
                                    <textarea className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" rows={5} placeholder="Write your Treatment Type Points here....." value={treatmentTypePoints} onChange={(e) => setTreatmentTypePoints(e.target.value)} disabled />
                                </div>
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Payment Info <sup className="text-red-500">*</sup></label>
                                    <textarea className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" rows={5} placeholder="Write your Payment Info Points here....." value={paymentInfoPoints} onChange={(e) => setPaymentInfoPoints(e.target.value)} disabled />
                                </div>
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Speciality Service <sup className="text-red-500">*</sup></label>
                                    <textarea className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" rows={5} placeholder="Write your Specility Service Points here....." value={specialityServicePoints} onChange={(e) => setSpecialityServicePoints(e.target.value)} disabled />
                                </div>
                                <div className="lg:col-span-1 col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Rating <sup className="text-red-500">*</sup></label>
                                    <input type="number" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. 4" value={rating} onChange={(e) => setRating(e.target.value)} disabled />
                                </div>
                                <div className="lg:col-span-1 col-span-2">
                                    {/* <div className="flex justify-between items-center mb-7">
                                        <h4 className="text-gray-700 dark:text-gray-300 text-xl font-semibold">
                                            Features
                                        </h4>
                                        <input type="checkbox" name="" className="custom-switch" />
                                    </div> */}
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Activate Listing</label>
                                    <div className="mt-5" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <input type="checkbox" className="custom-switch" checked={Number(activeteListing) === 1 ? true : false} onChange={(e) => setActivateListing(e.target.checked)} disabled />
                                        <label className="text-base text-gray-700 dark:text-gray-200 ml-5">{Number(activeteListing) === 1 ? "Active" : "De-Active"}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="flex flex-wrap sm:justify-start justify-center">
                            <button type="button" className="px-6 py-5 bg-blue-500 hover:bg-blue-600 shadow-glow-blue text-white rounded-lg mr-4 mb-4" onClick={() => updateListing()}>
                                Update List
                            </button>
                            <button type="button" className="px-6 py-[17px] mb-4 border border-gray-200 dark:border-gray-800 text-gray-500 dark:text-gray-200 rounded-lg mr-4" onClick={() => clearFields()}>
                                Cancel
                            </button>
                        </div> */}
                    </div>
                </section>
            </div>

            <div tabIndex={-1} aria-hidden="true" className={`${isVisible ? "flex" : "hidden"} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bottom-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`} style={{ backgroundColor: "#aaaaaabb" }}>
                <div className="relative p-4 w-full max-w-md max-h-full">
                    {/* Modal content */}
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        {/* Modal header */}
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-md font-semibold text-gray-900 dark:text-white">
                                Pay Now To Claim Listing
                            </h3>
                            <button type="button" className="end-2.5 bg-red-100 text-red-500 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="paypal-payment-modal" onClick={() => handleHide()}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                {/* <span className="sr-only">Close modal</span> */}
                            </button>
                        </div>
                        {/* Modal body */}
                        <div className="p-4 md:p-5">
                            {/* <div className="alert alert-info" role="alert">
                                One time claiming fee is <b>${process.env.REACT_APP__CLAIM_LISTING_FEE}</b>
                            </div> */}
                            <div className='mb-4 text-center text-lg'>
                                One time claiming fee is <b>${process.env.REACT_APP_CLAIM_LISTING_FEE}</b>
                            </div>
                            <PayPalScriptProvider options={initialOptions}>
                                <PayPalButtons
                                    style={{ layout: "vertical", tagline: false }}
                                    createOrder={createOrder}
                                    onApprove={onApprove}
                                    onError={onError}
                                    onCancel={onCancel}
                                />
                            </PayPalScriptProvider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClaimListing