import React from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

import ScrollToTopButton from '../../components/ScrollToTopButton';

// import businesses_list from "../../../business";
import FeaturedListing from '../../components/FeaturedListing';
import RehabSearchForm from '../../components/RehabSearchForm';
import MedicalReview from '../../components/MedicalReview';
import VisitOurFacility from '../../components/VisitOurFacility';
import TrendingListing from "../../components/TrendingListing";

const OpioidAddictionRiskFactors = () => {

  return (
    <>
      <Header />

      <PageContentContainer>

        <>
          <section className="dark:bg-background mb-12">
            <div className="relative">
              <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                <h1 className="capitalize text-4xl text-white font-semibold">Opioid Addiction Risk Factors</h1>
                <p className="text-white mt-2">7 Risk Factors for Addiction and Opioid Use</p>
              </div>
            </div>
          </section>

          <div className="container mx-auto px-5">
            <div className="grid grid-cols-3 gap-7 pb-10">

              <div className="lg:col-span-2 col-span-3">
                
                <MedicalReview type={"mobile"} />

                <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-4.jpg" alt="" /> */}
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-3.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-4.jpg" alt="" /> */}
                  <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                    {/* <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h5> */}

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">The usage of prescription opioids is becoming more and more contentious as the opioid crisis in the US worsens. People are also becoming more knowledgeable of the risks associated with consuming opioids and the hazards they pose. Even while taking medications exactly as recommended, anybody might develop an addiction to opioids. However, because of certain risk factors, some people are more likely to use and get addicted. Knowing these things can make it easier for people to decide if using opioids is risky and worth it. Let's examine seven risk factors linked to addiction and opioid dependency.</p>

                    {/* <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi voluptates accusantium molestias magnam beatae modi dolores corrupti aliquam saepe fugit, vero repudiandae praesentium eum iste quia numquam ipsum, reiciendis consectetur!
                      Illo velit distinctio magnam corrupti quasi vero reprehenderit. Ab assumenda enim ipsam quia velit facere, maxime cumque eveniet eligendi ea officiis! Optio totam sit animi hic dolorum obcaecati illum asperiores.
                      Impedit deserunt illo accusantium? Nobis ex modi cum hic sed distinctio possimus placeat quos nam aut. Laborum obcaecati quis, rem exercitationem eum aliquam non? At magnam placeat molestias cum tempora!
                      Rerum sint praesentium voluptas deserunt officia nobis, porro recusandae necessitatibus omnis vero beatae perspiciatis expedita aperiam reiciendis eaque nostrum quis consequuntur iste eum ad sequi corrupti! Explicabo nobis sit praesentium.</p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam vel ea sunt molestias facere quidem! Unde, repellendus dolores neque voluptatibus id, sunt impedit, nobis molestiae quisquam aut aliquid rem sed.
                      Doloremque, voluptatum voluptas iure facere, laudantium molestiae sint quam corporis illum ad amet recusandae vitae ea, accusamus dicta! Expedita accusantium quo reprehenderit! Repellendus quod eos tenetur nesciunt deleniti, cumque enim.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda facilis eum aliquam delectus deleniti corrupti nulla veniam cumque velit, veritatis eos quia dolorum enim ut quidem? Beatae officia aliquam voluptas!
                      Dolorum quia at maxime aliquam distinctio, molestias ratione nesciunt quod cupiditate laudantium aspernatur consequatur eaque vero id eius rerum libero dolore nobis modi. Dicta, illum architecto expedita explicabo laboriosam perferendis.
                      Debitis maiores pariatur enim voluptatum? Optio ipsa dignissimos, dolore, debitis adipisci veniam sed et labore, odio ab incidunt nihil officiis temporibus ut nemo aliquid ad eos rerum qui deserunt ipsam!</p> */}

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      1. Heredity
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">A family history of drug or alcohol abuse and addiction increases a person's risk of developing a substance use disorder. Indeed, research has indicated that a person's genetic composition may account for as much as half of their chance of developing an addiction. A person's chance of developing an addiction to opioids or other substances increases with the number of addiction cases in their immediate family.</p>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      2. Past Substance Use History
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Individuals with a history of drug or alcohol abuse or any type of addiction are at a higher risk of developing an opioid addiction. Before starting an opioid prescription, disclose to your healthcare professional any history of drug addiction, including tobacco addiction.</p>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      3. Environmental Factors
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      The likelihood that someone will acquire a drug use problem can be raised by several environmental variables. These contextual elements may be historical or current, and they might consist of:
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Exposure to emotional, sexual, or bodily abuse
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Having easy access to addictive substances
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Experiencing hardships like divorce or the death of a loved one
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Growing up in a home where drug and alcohol use were prevalent
                        </div>
                      </li>
                    </ul>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Opioid usage or addiction may occur as a result of exposure to one or more of these environmental variables.</p>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      4. Psychological Factors
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Mental health issues and other psychological variables might make someone more susceptible to developing an addiction to opioids. Someone experiencing depression, for instance, can use drugs as a coping mechanism for their symptoms. In a similar vein, an anxious person can consider using opioids to help them relax and calm down. Regretfully, addiction frequently exacerbates mental illness and increases the difficulty of fully recovering from both disorders.</p>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      5. Age
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Research has indicated that opiate use and addiction are more common in younger people (ages 18 to 30) and elderly people (ages 50 and beyond). Furthermore, the age range of 16 to 45 is associated with a higher incidence of opioid-related mortality.</p>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      6. Chronic Pain
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Opioids are mostly provided to patients to treat and manage chronic pain. Chronic pain can be caused by several illnesses, such as back issues, arthritis, cancer, and other long-term medical conditions. Unfortunately, a person's propensity to use opioids to try and control their pain increases with the length of time they experience it. Long-term usage of opioids can significantly raise one's chance of becoming dependent on and addicted to these drugs.</p>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      7. Application Technique
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">A person's risk of becoming addicted to opioids can also be influenced by how they take the drug. Tobacco tolerance and dependency may develop fast, for instance, if someone breaks up an opiate prescription and snorts it to get a stronger high. In addition, the risk of addiction can be significantly increased by smoking or injecting opioids like heroin.</p>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      Receiving Assistance for Addiction and Opioid Usage
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">It's critical to understand that recovery from opioid addiction is attainable if you or someone you love is experiencing difficulties. Although a drug use issue can be crippling, getting therapy can help you take back your life. You can beat addiction and live a happy, rewarding life in recovery with our assistance. Get in touch with a treatment professional right now to find out more about the risk factors connected to opioid use and addiction.</p>
                  </div>
                </div>

                <VisitOurFacility />

              </div>

              <div className="lg:col-span-1 col-span-3">

                <MedicalReview type={"desktop"} />

                <RehabSearchForm />

                {/* {featuredListing()} */}
                {<FeaturedListing items={5} />}

              </div>

            </div>
          </div>

        </>

        <TrendingListing />

        <ScrollToTopButton />

        <Footer />
      </PageContentContainer>

    </>
  )
}

export default OpioidAddictionRiskFactors