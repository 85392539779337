import React, { useEffect } from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

import ScrollToTopButton from '../../components/ScrollToTopButton';

import { appendScript, removeScript } from "../../func";

// import businesses_list from "../../../business";
import FeaturedListing from '../../components/FeaturedListing';
import RehabSearchForm from '../../components/RehabSearchForm';
import MedicalReview from '../../components/MedicalReview';
import VisitOurFacility from '../../components/VisitOurFacility';
import TrendingListing from "../../components/TrendingListing";

const SignsOfOpioidAddiction = () => {

  useEffect(() => {
    appendScript("/js/plugins-core/handy-collapse.js", "handy-collapse");
    setTimeout(() => {
      appendScript("/js/plugins-script/handy-collapse.js", "handy-collapse-function");
    }, 100)

    return () => {
      removeScript("handy-collapse");
      removeScript("handy-collapse-function");
    }

  }, []);

  return (
    <>
      <Header />

      <PageContentContainer>

        <>
          <section className="dark:bg-background mb-12">
            <div className="relative">
              <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                <h1 className="capitalize text-4xl text-white font-semibold">Signs Of Opioid Addiction</h1>
                <p className="text-white mt-2">Causes of Opiate Abuse: Signs, Symptoms, and Adverse Effects</p>
              </div>
            </div>
          </section>

          <div className="container mx-auto px-5">
            <div className="grid grid-cols-3 gap-7 pb-10">

              <div className="lg:col-span-2 col-span-3">

                <MedicalReview type={"mobile"} />

                <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building.jpg" alt="" /> */}
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-3.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-4.jpg" alt="" /> */}
                  <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                    {/* <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h5> */}

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Often, it can be challenging to recognize the warning symptoms of opioid addiction. Knowing the warning signs, symptoms, and adverse effects of opioid addiction is one of the most crucial phases of the rehabilitation process.</p>

                    {/* <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi voluptates accusantium molestias magnam beatae modi dolores corrupti aliquam saepe fugit, vero repudiandae praesentium eum iste quia numquam ipsum, reiciendis consectetur!
                      Illo velit distinctio magnam corrupti quasi vero reprehenderit. Ab assumenda enim ipsam quia velit facere, maxime cumque eveniet eligendi ea officiis! Optio totam sit animi hic dolorum obcaecati illum asperiores.
                      Impedit deserunt illo accusantium? Nobis ex modi cum hic sed distinctio possimus placeat quos nam aut. Laborum obcaecati quis, rem exercitationem eum aliquam non? At magnam placeat molestias cum tempora!
                      Rerum sint praesentium voluptas deserunt officia nobis, porro recusandae necessitatibus omnis vero beatae perspiciatis expedita aperiam reiciendis eaque nostrum quis consequuntur iste eum ad sequi corrupti! Explicabo nobis sit praesentium.</p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam vel ea sunt molestias facere quidem! Unde, repellendus dolores neque voluptatibus id, sunt impedit, nobis molestiae quisquam aut aliquid rem sed.
                      Doloremque, voluptatum voluptas iure facere, laudantium molestiae sint quam corporis illum ad amet recusandae vitae ea, accusamus dicta! Expedita accusantium quo reprehenderit! Repellendus quod eos tenetur nesciunt deleniti, cumque enim.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda facilis eum aliquam delectus deleniti corrupti nulla veniam cumque velit, veritatis eos quia dolorum enim ut quidem? Beatae officia aliquam voluptas!
                      Dolorum quia at maxime aliquam distinctio, molestias ratione nesciunt quod cupiditate laudantium aspernatur consequatur eaque vero id eius rerum libero dolore nobis modi. Dicta, illum architecto expedita explicabo laboriosam perferendis.
                      Debitis maiores pariatur enim voluptatum? Optio ipsa dignissimos, dolore, debitis adipisci veniam sed et labore, odio ab incidunt nihil officiis temporibus ut nemo aliquid ad eos rerum qui deserunt ipsam!
                    </p> */}

                    <div>
                      <button type="button" data-hc-control="content01" aria-expanded="false" className="px-5 py-2 w-full text-left border-b border-t border-gray-200 dark:border-foreground accordion-border-bottom-none flex justify-between text-base lg:text-lg font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent01">
                        Understanding Opioid Addiction
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 ml-5 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                        </svg>
                      </button>
                      <div id="basicContent01" className="px-5 border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content01" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
                        {/* <div className="pt-3 dark:text-gray-400 text-gray-500 leading-[24px]">
                          <b>[1]</b>. National Institute on Alcohol Abuse and Alcoholism. (2020). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.niaaa.nih.gov/publications/brochures-and-fact-sheets/alcohol-facts-and-statistics">Alcohol Facts and Statistics</a>.
                        </div> */}
                        {/* <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mt-2 mb-5">
                          Learn about opioids and substance abuse
                        </h4>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Opiates, which are typically referred to as narcotics, are most commonly used for pain relief and to induce sleep. These drugs are originally derived from the seeds of poppy plants or their byproducts. Most opiates are synthetic, but some naturally occurring forms include opium and morphine.</p>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">This drug class produces an intense sense of euphoria and safety in addition to pain-relieving properties, which makes them highly addictive. Many people who present with pain disorders later become addicted to pharmaceutical opiates like hydrocodone and oxycodone.</p>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">The overuse of opiates causes many negative problems for the user. Prolonged opioid usage results in the inability for the brain to naturally produce endorphins, which are the body’s natural painkillers. When the body is unable to properly regulate and manage pain, an opiate user may develop an increased reliance on the drug as the opiates are now used to manage pain and create an overall sense of happiness and contentment.  Over a prolonged period of time an individual will need more of the substance in order to obtain the same level of high they first experienced, which is called "tolerance."</p>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">If an opiate addict decides to stop taking opiates, his or her body will begin to go through withdrawal, which is a cluster of symptoms that are highly unpleasant and may result in the user seeking more opiates to alleviate these very nasty feelings.</p>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">However, with a proper inpatient treatment program and medical detoxification process, people who are addicted to opiates can go on and live a sober and joyful life.</p>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">However, with a proper inpatient treatment program and medical detoxification process, people who are addicted to opiates can go on and live a sober and joyful life.</p> */}

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5 mt-5">Addressing the widespread crisis that opioid addiction has caused requires an understanding of the addiction. Chronic opioid addiction disrupts the brain's reward system, resulting in physical dependency and compulsive drug-seeking behavior even in the face of danger. Prescription medicines are frequently the first to be abused, but misuse of harsher drugs like heroin or synthetic opioids may happen very fast. Comprehensive care is necessary to break the cycle of addiction. This care should include behavioral therapy, medical detox, and long-term support to address the underlying reasons of the addiction. Combating opioid addiction requires increasing access to treatment, decreasing stigma, and raising awareness.</p>
                      </div>

                      <button type="button" data-hc-control="content02" style={{ marginTop: "-2px" }} aria-expanded="false" className="px-5 py-2 w-full text-left border-b border-t border-gray-200 dark:border-foreground accordion-border-bottom-none flex justify-between text-base lg:text-lg font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent02">
                        Statistics
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 ml-5 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                        </svg>
                      </button>
                      <div id="basicContent02" className="px-5 border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content02" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
                        {/* <div className="pt-3 dark:text-gray-400 text-gray-500 leading-[24px]">
                          <b>[1]</b>. National Institute on Alcohol Abuse and Alcoholism. (2020). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.niaaa.nih.gov/publications/brochures-and-fact-sheets/alcohol-facts-and-statistics">Alcohol Facts and Statistics</a>.
                        </div> */}
                        {/* <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mt-2 mb-5">
                          Opioid addiction statistics
                        </h4>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Opiate abuse is one of the most common forms of drug abuse in the United States. It is estimated that .37% of adults have an opiate addiction. The highest rates of opiate use are found in those 29 years old and younger (.82%) while rates decrease with age to .09% in adults over age 65. Overall, men are twice as likely as women to become addicted to opiates.</p> */}

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5 mt-5">Statistics on opioid addiction worldwide demonstrate the seriousness of the problem, which has grown to be a significant public health issue. The World Health Organization (WHO) reports that drug usage is responsible for over 500,000 fatalities every year, with opioids making up the majority of these deaths. Globally, it was estimated that 61 million individuals used opioids in 2020 alone. These included heroin, prescription painkillers, and synthetic opioids like fentanyl. North America has a particularly bad opioid crisis, with a sharp increase in opioid-related fatalities in recent years. The widespread effects of opioid addiction highlight the pressing need for harm-reduction, preventive, and treatment plans that are integrated to address this issue.</p>
                      </div>

                      <button type="button" data-hc-control="content03" style={{ marginTop: "-2px" }} aria-expanded="false" className="px-5 py-2 w-full text-left border-b border-t border-gray-200 dark:border-foreground accordion-border-bottom-none flex justify-between text-base lg:text-lg font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent03">
                        Causes and Risk Factors
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 ml-5 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                        </svg>
                      </button>
                      <div id="basicContent03" className="px-5 border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content03" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
                        {/* <div className="pt-3 dark:text-gray-400 text-gray-500 leading-[24px]">
                          <b>[1]</b>. National Institute on Alcohol Abuse and Alcoholism. (2020). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.niaaa.nih.gov/publications/brochures-and-fact-sheets/alcohol-facts-and-statistics">Alcohol Facts and Statistics</a>.
                        </div> */}
                        {/* <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mt-2 mb-5">
                          Causes and risk factors for opioid addiction
                        </h4>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Although anyone can become an opioid addict, there are a number of factors that have been hypothesized as causes of the addiction. These include:</p>

                        <ul className=" mb-5">
                          <li className="mb-1">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              <div><b>Genetic: </b>Research has suggested that some individuals are genetically predisposed to develop an opiate addiction. Individuals who have family members that are addicted to opiates or other substances appear to be more likely to develop an opiate addition than those without a family history of the disorder.</div>
                            </div>
                          </li>
                          <li className="mb-1">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              <div><b>Biological: </b>One theory suggests that some individuals do not produce enough natural endorphins in their brain which can affect their mood. This chemical imbalance may lead to opiate use, as a means of coping with negative feelings caused by this imbalance.</div>
                            </div>
                          </li>
                          <li className="mb-1">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              <div><b>Psychological: </b> For certain individuals it has been hypothesized that exposure to opiates for pain can lead to an addiction. Due to the euphoria, decreased negative mood and anxiety an individual may find that they are able to better function in social settings and school/professional settings when using opiates. This can lead to the start of an opiate addiction because individuals come to believe that they are unable to function without the substance.</div>
                            </div>
                          </li>
                          <li className="mb-1">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              <div><b>Environmental: </b>Finally, environmental factors likely play a role in the causing the development of an opiate addiction. It has been hypothesized that the use of opiates may be modeled by someone in the individual’s life. One possibility is that a parent or other relative living with the individual may become addicted to opiates after using them for pain control. If this relative continues to use the opiate after the pain has resolved in front of the individual or uses them to cope with stressors, that individual in question may come to believe that this is an acceptable form of coping with stressors in life.</div>
                            </div>
                          </li>
                        </ul>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Overall, there is no single cause of opiate addiction, but it is believed that it is caused by a combination of multiple factors.</p> */}
                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5 mt-5">Opioid addiction has several complicated causes and risk factors that include biological, psychological, and environmental elements. Prolonged use of prescription opioids for pain relief can be one of the main reasons since it can result in addiction and dependency. Genetic predisposition is important because some people have a family history that makes them more susceptible to addiction. Significant risk factors include mental health conditions including anxiety, sadness, and trauma, which frequently result in the use of opioids for self-medication. Peer pressure, drug exposure, and a lack of support networks are examples of environmental variables that raise the likelihood of opioid addiction. For prevention and treatment to be effective, it is important to comprehend these factors.</p>
                      </div>

                      <button type="button" data-hc-control="content04" style={{ marginTop: "-2px" }} aria-expanded="false" className="px-5 py-2 w-full text-left border-b border-t border-gray-200 dark:border-foreground accordion-border-bottom-none flex justify-between text-base lg:text-lg font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent04">
                        Signs and Symptoms
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 ml-5 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                        </svg>
                      </button>
                      <div id="basicContent04" className="px-5 border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content04" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
                        {/* <div className="pt-3 dark:text-gray-400 text-gray-500 leading-[24px]">
                          <b>[1]</b>. National Institute on Alcohol Abuse and Alcoholism. (2020). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.niaaa.nih.gov/publications/brochures-and-fact-sheets/alcohol-facts-and-statistics">Alcohol Facts and Statistics</a>.
                        </div> */}
                        {/* <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mt-2 mb-5">
                          Signs and symptoms of opioid addiction
                        </h4>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Opiate abuse is one of the most common forms of drug abuse in the United States. It is estimated that .37% of adults have an opiate addiction. The highest rates of opiate use are found in those 29 years old and younger (.82%) while rates decrease with age to .09% in adults over age 65. Overall, men are twice as likely as women to become addicted to opiates.</p>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Mood Symptoms:</p>

                        <ul className=" mb-5">
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Lack of motivation
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Depressed mood
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Hyperactivity
                            </div>
                          </li>
                        </ul>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Physical Symptoms:</p>

                        <ul className=" mb-5">
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Weight loss
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Cramping
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Diarrhea
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Itchy skin
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Joint and muscle pain
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Nausea and vomiting
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Headaches
                            </div>
                          </li>
                        </ul>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Psychological Symptoms:</p>

                        <ul className=" mb-5">
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Loss of concentration or interest
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Confusion or disorientation
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Mood swings or extreme behavior changes
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Depression
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Anxiety
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Distorted perception of reality
                            </div>
                          </li>
                        </ul>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Behavioral Symptoms:</p>

                        <ul className=" mb-5">
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Withdrawn socially
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Slowed or slurred speech
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Diminished coordination
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Loss of interest in activities once enjoyed
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Stealing from loved ones or other illegal activities
                            </div>
                          </li>
                        </ul> */}

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5 mt-5">Early intervention and treatment for opioid addiction depend heavily on the ability to recognize the symptoms of the addiction. Strong cravings for opioids, an inability to stop using the substance or cut back, and continued use of the drug despite negative effects on one's personal and professional life are typical indicators. Physical effects include fatigue, changes in sleep patterns, weight loss, and notable withdrawal symptoms such as sweating, nausea, and agitation when the medicine is stopped. Other telltale signs include behavioral shifts, including cutting back on social interactions, skipping out on obligations, and acting riskily. Early detection of these signs can help stop the addiction from getting worse and provide access to treatment alternatives promptly.</p>

                      </div>

                      <button type="button" data-hc-control="content05" style={{ marginTop: "-2px" }} aria-expanded="false" className="px-5 py-2 w-full text-left border-b border-t border-gray-200 dark:border-foreground accordion-border-bottom-none flex justify-between text-base lg:text-lg font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent05">
                        Effects
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 ml-5 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                        </svg>
                      </button>
                      <div id="basicContent05" className="px-5 border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content05" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
                        {/* <div className="pt-3 dark:text-gray-400 text-gray-500 leading-[24px]">
                          <b>[1]</b>. National Institute on Alcohol Abuse and Alcoholism. (2020). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.niaaa.nih.gov/publications/brochures-and-fact-sheets/alcohol-facts-and-statistics">Alcohol Facts and Statistics</a>.
                        </div> */}
                        {/* <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mt-2 mb-5">
                          Effects of opioid addiction
                        </h4>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Opiate addiction can have many consequences on all aspects of an individual’s life. Some of these effects include:</p>

                        <ul className=" mb-5">
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Social problems (loss of support of family and friends, isolation)
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Work problems
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Problems at school
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Financial problems
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Insomnia
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Adverse health consequences (abscesses, pulmonary complications, infections in the heart, collapsed veins, permanent lung, liver, and kidney damage)
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              History of drug related crimes and incarceration
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Involuntary commitment to a mental hospital
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Death from overdose or suicide
                            </div>
                          </li>
                        </ul> */}

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5 mt-5">Opioid addiction has severe and pervasive repercussions that affect almost every part of a person's life. Physically, it can cause serious health problems such as immune system weakness, respiratory depression, and an elevated chance of overdosing, which can be lethal. Opioid addiction psychologically frequently makes mental health issues, including anxiety, depression, and cognitive decline, worse. Socially, those who struggle with addiction may face financial hardships, lost jobs, and damaged relationships. Extended usage can change the chemistry of the brain, making recovery a difficult process. Opioid addiction extends beyond the individual to impact families, communities, and healthcare systems, therefore exacerbating a wider public health emergency.</p>
                      </div>

                      <button type="button" data-hc-control="content06" style={{ marginTop: "-2px" }} aria-expanded="false" className="px-5 py-2 w-full text-left border-b border-t border-gray-200 dark:border-foreground accordion-border-bottom-none flex justify-between text-base lg:text-lg font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent06">
                        Co-Occurring Disorders
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 ml-5 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                        </svg>
                      </button>
                      <div id="basicContent06" className="px-5 border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content06" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
                        {/* <div className="pt-3 dark:text-gray-400 text-gray-500 leading-[24px]">
                          <b>[1]</b>. National Institute on Alcohol Abuse and Alcoholism. (2020). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.niaaa.nih.gov/publications/brochures-and-fact-sheets/alcohol-facts-and-statistics">Alcohol Facts and Statistics</a>.
                        </div> */}
                        {/* <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mt-2 mb-5">
                          Opioid addiction and co-occurring disorders
                        </h4>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">It is common for individuals suffering from opiate abuse to have another psychiatric illness. Some co-occurring disorders that may be present with opiate addiction include:</p>

                        <ul className=" mb-5">
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Depression
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Alcohol and other substance abuse
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Schizophrenia
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Eating disorders
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Antisocial personality disorder
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Anxiety disorders (post-traumatic stress disorder, obsessive-compulsive disorder, generalized anxiety disorder)
                            </div>
                          </li>
                        </ul> */}
                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5 mt-5">Co-occurring disorders, also referred to as dual diagnosis, are prevalent in those who are addicted to opioids. These describe the co-occurrence of a mental health illness, such as bipolar disorder, PTSD, depression, or anxiety, with a drug use problem. On the other hand, untreated mental health issues might lead people to turn to opioids as a kind of self-medication. Opioid addiction frequently exacerbates mental health symptoms. This starts a loop that exacerbates both problems and increases the complexity of the treatment. To treat co-occurring disorders and promote long-term recovery and general well-being, an integrated strategy that combines mental health therapy with addiction treatment is necessary.</p>
                      </div>

                      <button type="button" data-hc-control="content07" style={{ marginTop: "-2px" }} aria-expanded="false" className="px-5 py-2 w-full text-left border-b border-t border-gray-200 dark:border-foreground accordion-border-bottom-none flex justify-between text-base lg:text-lg font-medium text-gray-800 dark:text-gray-200" aria-controls="basicContent07">
                        Effects of Withdrawal
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800 dark:text-gray-400 ml-5 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                        </svg>
                      </button>
                      <div id="basicContent07" className="px-5 border-b border-gray-200 dark:border-foreground dark:text-gray-200" data-hc-content="content07" aria-hidden="true" style={{ maxHeight: '0px', visibility: 'hidden', overflow: 'hidden' }}>
                        {/* <div className="pt-3 dark:text-gray-400 text-gray-500 leading-[24px]">
                          <b>[1]</b>. National Institute on Alcohol Abuse and Alcoholism. (2020). <a className='text-blue-500 font-bold hover:text-blue-500' href="https://www.niaaa.nih.gov/publications/brochures-and-fact-sheets/alcohol-facts-and-statistics">Alcohol Facts and Statistics</a>.
                        </div> */}
                        {/* <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mt-2 mb-5">
                          Effects of withdrawal from opioids
                        </h4>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Prolonged opiate use can cause an individual to become physically and psychologically dependent on the substance. Opiate withdrawal refers to the negative symptoms that occur when someone who is dependent on one of these drugs stops using it. There are a variety of symptoms that an individual can experience when going through withdrawal. Those include:</p>

                        <ul className=" mb-5">
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Irritation or agitation
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Cravings
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Sweating
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Muscle aches
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Cramps
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Anxiety
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Nausea and vomiting
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Shakes
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Insomnia
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Diarrhea
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                              <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                              </svg>
                              Suicidal ideations
                            </div>
                          </li>
                        </ul>

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Some of these withdrawal symptoms can set in as early as a few hours after the last dose of the substance and can last as long as a few days.</p> */}

                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5 mt-5">Withdrawal from opioids may be very painful and physically crippling, making it one of the hardest parts of recovering from addiction. After the last dosage, symptoms usually start a few hours later and might include intense cravings, perspiration, nausea, vomiting, pains in the muscles, anxiety, and sleeplessness. Withdrawal may occasionally result in high blood pressure, a fast heartbeat, and dehydration, all of which call for medical attention. There is a notable psychological cost as well; many people report feeling anxious, depressed, and despairing. Although uncomfortable, withdrawal symptoms do not usually constitute a serious threat to life, but they can be a significant obstacle to recovery if appropriate medical and psychological care is not received.</p>
                      </div>

                    </div>
                  </div>
                </div>

                <VisitOurFacility />
                
              </div>

              <div className="lg:col-span-1 col-span-3">

                <MedicalReview type={"desktop"} />

                <RehabSearchForm />

                {/* {featuredListing()} */}
                {<FeaturedListing items={5} />}

              </div>

            </div>
          </div>

        </>

        <TrendingListing />

        <ScrollToTopButton />

        <Footer />
      </PageContentContainer>

    </>
  )
}

export default SignsOfOpioidAddiction